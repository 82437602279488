import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Auth} from "aws-amplify";
import Foot from "../components/Foot";
import {Form, Row, Col, Button} from 'react-bootstrap';
import Navbars from "../components/Navbar";
import "./css/profile.css";
import Login from "./login";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";


const Profile = ()  => {
    const history = useNavigate();
    const [user, setUser] = useState();
    const [isEmail, setIsemail] = useState();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                const username = user.attributes.email;
                setIsemail(username);
            })
    }, []);

    const handleSignout = async () => {
        try {
            await Auth.signOut();
            history("/");
            window.location.reload();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    async function deleteUser() {
        let text = "Are you sure you want to delete this account? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                await Auth.deleteUser();
                history("/");
                window.location.reload();
            } catch (error) {
                console.log('Error deleting user', error);
            }
        }
    }
    return(
        <>
            <Helmet>
                <title>MY ACCOUNT</title>
            </Helmet>
            <Navbars />
            {!user ?
                <Login />
                :(
                <div className="profiles">
                    <br/>
                    <div className="cccenter">
                        <h2> My Account </h2>
                    </div>
                    <br/>
                    <br/>
                    <Grid container spacing={0} className="topspace1">
                        <Grid item xs={2} className="cccenter">
                        </Grid>
                        <Grid item xs={4} className="cccenter">
                            Email:
                        </Grid>
                        <Grid item xs={4} className="cccenter">

                            <td><p>{isEmail}</p> <Button user={user} onClick={handleSignout} variant="success" size="sm">Sign Out</Button></td>
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                        </Grid>
                    </Grid>
                      <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    <br/>
                    <div className="cccenter">
                                <Button user={user} onClick={deleteUser} variant="light" size="sm">Delete Account</Button>
                    </div>

                    </div>
                )
            }
                    <Foot />
                </>

    )
}


export default Profile;
