import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getFlipcard, listAnswerslists, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import "../../css/flip.css";
import Flipshow from "./flipshow";
import {v4 as uuid} from 'uuid';
import moment from "moment";
import {createAnswerslist, createLeaderboard} from "../../../graphql/mutations";
import People from "../../../img/overview/people.png";
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import Submitteds from "../../../img/overview/submit.png";
import Grid from "@material-ui/core/Grid";


function Flipshowroom() {
    const [isShow, setIsShow] = useState(true);
    const [counter, setCounter] = useState();
    const [cards, setCards] = useState([]);
    const {id, rid} = useParams();
    const starts = moment().format('MM/DD/YYYY hh:mm a');
    const [isShowpeoples, setIsShowpeoples] = useState(false);
    const [peoplenumbers, setPeoplenumbers] = useState(0);
    const [peoples, setPeoples] = useState([]);
    const d = uuid();
    const [answerednumber, setAnswerednumbers] = useState(0);
    sessionStorage.setItem("edureachumthisstartstimer", starts)
    const start = new Date();
    sessionStorage.setItem("edureachumthisstarttimer", start.toString());
    const [thisname, setThisname] = useState(localStorage.getItem('vreachumnewername') || '');
    const [thisemail, setThisemail] = useState(localStorage.getItem('vreachumneweremail')||'');
    const loadUserData = () => {
        try {
            const storedName = localStorage.getItem('vreachumnewername');
            const storedEmail = localStorage.getItem('vreachumneweremail');

            if (storedName) setThisname(storedName);
            if (storedEmail) setThisemail(storedEmail);

            // Set dataLoaded to true if either name or email exists
            if (storedName || storedEmail) {
                setIsShowpeoples(false)
            }

        } catch (error) {
            console.error('Error loading data from localStorage:', error);
        }
    };
    useEffect(() => {
        loadUserData();

        const handleMessage = (event) => {
            if (event.data === 'dataUpdated') {
                loadUserData();
            }
        };

        window.addEventListener('message', handleMessage);

        const timeoutId = setTimeout(() => {
            loadUserData();
        }, 500);

        // Clean up listener and timeout when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
            clearTimeout(timeoutId);
        };
    }, []);
    useEffect(() => {
        fetchDas();
    }, []);

    useEffect(() => {
        const starts = moment().format('MM/DD/YYYY hh:mm a');

        const createAnswersEntry = async () => {
            if (!thisemail) {
                setIsShowpeoples(true);
                return;
            }

            try {
                const input = {
                    id: d,
                    answerid: `2${rid}`,
                    name: thisname,
                    email: thisemail
                };

                await API.graphql({
                    query: createAnswerslist,
                    variables: { input }
                });
                sessionStorage.setItem("thisflipshowroomreachumid", d)
            } catch (err) {
                console.log("Error creating answers entry:", err);
            }
        };

        const checkAndCreateLeaderboard = async () => {
            if (!thisemail) return;

            try {
                // First check if a leaderboard entry exists
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: thisemail },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const plays = response.data.listLeaderboards.items;
                if (plays.length > 0) {
                    // Existing leaderboard found
                    const existingEntry = plays[0];
                    sessionStorage.setItem("edureachumthisid", existingEntry.id);

                    if (existingEntry.questions) {
                        const serializedArray = JSON.stringify(existingEntry.questions);
                        sessionStorage.setItem("edureachumthisques", serializedArray)

                    }
                    if(existingEntry.score !== undefined) {
                        sessionStorage.setItem("edureachumthistotalscore", existingEntry.score)
                    }else{
                        sessionStorage.setItem("edureachumthistotalscore", 0)
                    }
                } else {
                    // Create new leaderboard entry
                    const input = {
                        name: thisname,
                        meetemail: thisemail,
                        answerid: id,
                        score: 0,
                        thisgamescore: 0,
                        timerin: starts
                    };
                    try {
                        const responsee = await API.graphql({
                            query: createLeaderboard,
                            variables: { input }
                        });

                        // Fix accessing the id from the response
                        const thistimerid = responsee.data.createLeaderboard.id;
                        sessionStorage.setItem("edureachumthisid", thistimerid);
                        sessionStorage.setItem("edureachumthistotalscore", 0)
                    } catch (err) {
                        console.error("Error creating leaderboard:", err);
                    }
                }
            } catch (err) {
                console.error("Error querying leaderboards:", err);
            }
        };

        // Execute both operations
        const initializeData = async () => {
            await createAnswersEntry();
            await checkAndCreateLeaderboard();
        };

        initializeData();
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getFlipcard,
                variables: {id: rid}
            })
            const datas = response.data.getFlipcard;
            const timers = datas.ptimer;
            setCounter(timers)
            const ques = datas.fwords;
            setCards(JSON.stringify(ques))
        } catch (err) {
            console.log(err)
        }
    }

    function savethisdata() {
        localStorage.setItem("reachumflipcardgametimer", counter);
        localStorage.setItem("reachumflipcardgame", cards);
        setIsShow(false)
    }

    useEffect(() => {
        fetchData();
        const createanswerslist = API.graphql(
            {
                query: newCreateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updateanswerslist = API.graphql(
            {
                query: newUpdateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const deleteanswerslist = API.graphql(
            {
                query: newDeleteAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return () => {
            createanswerslist.unsubscribe();
            updateanswerslist.unsubscribe();
            deleteanswerslist.unsubscribe()
        }
    }, []);
    const fetchData = async () => {
        const response = await API.graphql({
            query: listAnswerslists,
            variables: {
                filter: {
                    "answerid": {
                        eq: `2${rid}`
                    }
                },
                limit: 10000
            }
        })

        let filteredData = response.data.listAnswerslists.items;
        const merged = mergeRecordsByEmail(filteredData)
        setPeoples(merged);
        setPeoplenumbers(merged.length);
        const countAnswered = merged.filter(record => record.answers !== null).length;
        setAnswerednumbers(countAnswered);
    }
    const mergeRecordsByEmail = (records) => {
        const mergedRecords = {};
        records.forEach(record => {
            const {email, answerid, answers} = record;
            if (!mergedRecords[email]) {
                mergedRecords[email] = {
                    email,
                    name: record.name,
                    answerid: null,
                    answers: null
                };
            }
            if (answers === "submited") {
                mergedRecords[email].answerid = answerid;
                mergedRecords[email].answers = answers;
            } else if (mergedRecords[email].answerid === null) {
                mergedRecords[email].answerid = answerid;
            }
        });

        // Return merged records as an array
        return Object.values(mergedRecords);
    };

    return (
        <>
            <div className="flipcardpage">
                {isShow ?
                    <>
                        <br/>
                        <br/>
                        <br/>
                        <h2>Memory matching game - Flipping card</h2>
                        <br/>
                        <div>
                            <p className="biggertext">Select two cards with same meaning content<br/> consecutively to
                                make them vanish.</p>
                        </div>
                        <div className="startbutton">
                            <Button onClick={savethisdata} variant="success">CLICK TO START</Button>
                        </div>
                    </>
                    :
                    <>
                        <Flipshow/>
                    </>
                }
            </div>
            {isShowpeoples ?
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={2} className="cccenter">
                        </Grid>
                        <Grid item xs={10}>
                        </Grid>
                    </Grid>
                    <div className="cccenter">
                        <h3><img src={People}/> {peoplenumbers} &nbsp; <img
                            src={Submitteds}/> {answerednumber}</h3>
                    </div>
                    <div>
                        <Table hover size="sm" className="sizeforbtable">
                            <thead>
                            <tr>
                                <th> Name</th>
                                <th className="cccenter"> Submitted</th>
                                <th className="cccenter"> % Correct</th>
                            </tr>
                            </thead>
                            <tbody>
                            {peoples.map((record, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                                        marginBottom: '10px',
                                    }} className="peopleviewsconitem">
                                    <td className="leftsitethis">{record.name}</td>
                                    <td>
                                        {record.answers === null ? "" : (
                                            <img src={Submitteds} width="20px"/>
                                        )}
                                    </td>
                                    <td>
                                        {record.answers === null ? "" : (
                                            <span>100%</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                : ""
            }
        </>
    )
}

export default Flipshowroom;
