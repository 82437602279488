import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {listPlayslides} from '../../../graphql/queries';
import DatePicker from 'react-datepicker';
import "../../css/morereportrooms.css";
import Grid from "@material-ui/core/Grid";


function Sharemorereports(props) {
    const thisemail = props.data;
    const [thisalldatas, setThisalldatas] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errormessage, setErrormessage] = useState("");
    const [items, setItems] = useState([]);

    const fetchItems = async (nextToken = null, allItems = []) => {
        try {
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisemail
                        }
                    },
                    nextToken
                }
            });
            const fetchedItems = response.data.listPlayslides.items;
            const newNextToken = response.data.listPlayslides.nextToken;

            const updatedItems = [...allItems, ...fetchedItems];

            if (newNextToken) {
                fetchItems(newNextToken, updatedItems);
            } else {
                if (updatedItems.length !== 0) {
                    setItems(updatedItems);
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    async function getdatas() {
        if (endDate > startDate) {
            const filteredDates = items.filter(item => {
                const updatedAtDate = new Date(item.createdAt);
                return updatedAtDate >= startDate && updatedAtDate <= endDate;
            });
            const filteredIds = filteredDates.map(item => ({
                id: item.id,
                meetingname: item.meetingname
            }));
            setThisalldatas(filteredIds)
        } else {
            setErrormessage("Display To is not greater than Display From")
        }
    }

    return (
        <>
            <div className="leaderpage">
                <br/>
                <div>
                    <Grid container spacing={0} className="topspace1">
                        <Grid item xs={1} className="cccenter">
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                            <p>Display From: </p>
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="MM/dd/yyyy"
                                className="dateinput"
                            />
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                            <p>Display To: </p>
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="MM/dd/yyyy"
                                className="dateinput"
                            />
                        </Grid>
                        <Grid item xs={2} className="cccenter">
                            <button onClick={getdatas} className="answersubmit">Search</button>
                        </Grid>
                        <Grid item xs={1} className="cccenter">
                        </Grid>
                    </Grid>
                    <p className="redthis">{errormessage}</p>
                </div>
                <div className="lessonslist">
                    <ol>
                        {thisalldatas.map((item, index) => (
                            <li key={index} className="biggertext"><p onClick={() => {
                                window.open(`https://edu.reachum.com/lessonLog/${item.id}`)
                            }}> {item.meetingname}</p></li>
                        ))}
                    </ol>
                </div>
            </div>

        </>
    )
}

export default Sharemorereports;
