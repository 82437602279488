import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getDesmosfillin, listLeaderboards, listAnswerslists} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {Button, Table} from "react-bootstrap";
import "../../css/poll.css";
import {
    createLeaderboard,
    updateLeaderboard,
    createAnswerslist,
    updateAnswerslist
} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {v4 as uuid} from 'uuid';
import moment from "moment";
import {
    newCreateAnswerslist,
    newUpdateAnswerslist,
    newDeleteAnswerslist
} from "../../../graphql/subscriptions";
import Modal from "react-modal";
import Close from "../../../img/host/close.png";
import People from "../../../img/overview/people.png";
import Submitteds from "../../../img/overview/submit.png"
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";


function Fasteranswerimgroom() {
    const [isShows, setIsShows] = useState(true);
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [ans, setAns] = useState([]);
    const [vals, setVals] = useState("");
    const {id, rid} = useParams();
    const [counter, setCounter] = useState();
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const [isopenimg, setIsopenimg] = useState(false);
    const [thisimg, setThisimg] = useState("");
    const [thiscorrect, setThiscorrect] = useState("");
    const [isYourShowcorrects, setIsYourShowcorrects] = useState(false);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [thissaved, setThissaved] = useState([]);
    const [isShowcorrects, setIsShowcorrects] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [showclock, setShowclock] = useState(false);
    const [usertackingid, setUsertackingid] = useState("");
    const [usertotascore, setUsertotascore] = useState(0);
    const [peoplenumbers, setPeoplenumbers] = useState(0);
    const [peoples, setPeoples] = useState([])
    const [isShowpeoples, setIsShowpeoples] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [thisid, setThisid] = useState("");
    const [answerednumber, setAnswerednumbers] = useState(0);
    const [thisiscorrect, setThisiscorrect] = useState(0);
    const [thisiswrong, setThisiswrong] = useState(0);
    const [starttime, setStarttime] = useState("");
    const [userquestions, setUserquestions] = useState([]);
    const d = uuid();
    const [thisname, setThisname] = useState(localStorage.getItem('vreachumnewername') || '');
    const [thisemail, setThisemail] = useState(localStorage.getItem('vreachumneweremail') || '');
    const loadUserData = () => {
        try {
            const storedName = localStorage.getItem('vreachumnewername');
            const storedEmail = localStorage.getItem('vreachumneweremail');

            if (storedName) setThisname(storedName);
            if (storedEmail) setThisemail(storedEmail);

            // Set dataLoaded to true if either name or email exists
            if (storedName || storedEmail) {
                setIsShowpeoples(false)
            }

        } catch (error) {
            console.error('Error loading data from localStorage:', error);
        }
    };
    useEffect(() => {
        loadUserData();

        const handleMessage = (event) => {
            if (event.data === 'dataUpdated') {
                loadUserData();
            }
        };

        window.addEventListener('message', handleMessage);

        const timeoutId = setTimeout(() => {
            loadUserData();
        }, 500);

        // Clean up listener and timeout when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
            clearTimeout(timeoutId);
        };
    }, []);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if (submitrids) {
            setThissaved(submitrids)
            if (submitrids.includes(rid)) {
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    useEffect(() => {
        const nowtime = new Date();
        setStarttime(nowtime);
        const starts = moment().format('MM/DD/YYYY hh:mm a');

        const createAnswersEntry = async () => {
            if (!thisemail || !thisname) {
                setIsShowpeoples(true);
                return;
            }

            try {
                const input = {
                    id: d,
                    answerid: `2${rid}`,
                    name: thisname,
                    email: thisemail
                };

                await API.graphql({
                    query: createAnswerslist,
                    variables: {input}
                });

                setThisid(d);
            } catch (err) {
                console.log("Error creating answers entry:", err);
            }
        };

        const checkAndCreateLeaderboard = async () => {
            if (!thisemail || !thisname) return;

            try {
                // First check if a leaderboard entry exists
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: {eq: thisemail},
                            answerid: {eq: id}
                        },
                        limit: 10000
                    }
                });

                const plays = response.data.listLeaderboards.items;
                if (plays.length > 0) {
                    // Existing leaderboard found
                    const existingEntry = plays[0];
                    setUsertackingid(existingEntry.id);
                    sessionStorage.setItem("edureachumthisid", existingEntry.id);

                    if (existingEntry.questions) {
                        setUserquestions(existingEntry.questions);
                    }

                    if (existingEntry.score !== undefined) {
                        setUsertotascore(existingEntry.score);
                    }
                } else {
                    // Create new leaderboard entry
                    const input1 = {
                        name: thisname,
                        meetemail: thisemail,
                        answerid: id,
                        score: 0,
                        thisgamescore: 0,
                        timerin: starts
                    };
                    try {
                        const responsee = await API.graphql({
                            query: createLeaderboard,
                            variables: {input: input1}
                        });
                        // Fix accessing the id from the response
                        const thistimerid = responsee.data.createLeaderboard.id;
                        setUsertackingid(thistimerid);
                        sessionStorage.setItem("edureachumthisid", thistimerid);
                        setUsertotascore(0);
                    } catch (err) {
                        console.error("Error creating leaderboard:", err);
                    }
                }
            } catch (err) {
                console.error("Error querying leaderboards:", err);
            }
        };

        // Execute both operations
        const initializeData = async () => {
            await createAnswersEntry();
            await checkAndCreateLeaderboard();
        };

        initializeData();
    }, []);
    useEffect(() => {
        fetchData();
        const createanswerslist = API.graphql(
            {
                query: newCreateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updateanswerslist = API.graphql(
            {
                query: newUpdateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const deleteanswerslist = API.graphql(
            {
                query: newDeleteAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return () => {
            createanswerslist.unsubscribe();
            updateanswerslist.unsubscribe();
            deleteanswerslist.unsubscribe()
        }
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getDesmosfillin,
                variables: {id: rid}
            })
            const datas = response.data.getDesmosfillin;
            const timers = datas.ftimer;
            if (timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            } else {
                setTimeval(40)
            }
            const ques = datas.fquestion;
            setQuest(ques);
            sessionStorage.setItem("edureachumthisquestion", ques)
            const thisimage = datas.mathstate;
            if (thisimage) {
                setThisimg(thisimage);
                setIsopenimg(true)
            }
            const tests = datas.fanswer.toLowerCase();
            setAns(tests);
            const ttr = datas.fanswer;
            setThiscorrect(ttr)
        } catch (err) {
            console.log(err)
        }
    }

    const handleChanges = e => {
        const thisval = e.target.value.toLowerCase()
        setVals(thisval);
    }
    const psubmit = async () => {
        // Store submission ID in session storage
        const submissionIds = thissaved ? [thissaved, rid] : [rid];
        sessionStorage.setItem("submitrid", submissionIds);

        // Stop the timer/animation
        setPlaypause(false);

        // Check if answer is correct
        const isCorrect = new RegExp(ans).test(vals);
        const end = new Date();
        const seconds = parseInt((end - starttime) / 1000 % 60);

        // Create question result object
        const questionResult = {
            rid,
            question: quest,
            correctanswer: isCorrect ? "correct" : "wrong",
            thisscore: isCorrect ? timeval : 0,
            duration: seconds
        };

        // Update questions array
        const updatedQuestions = userquestions ? [...userquestions, questionResult] : [questionResult];

        try {
            // Prepare data for API
            const input = {
                id: usertackingid,
                name: thisname,
                questions: updatedQuestions,
            };

            // Add score information for correct answers
            if (isCorrect) {
                const totalScore = timeval + usertotascore;
                input.thisgamescore = timeval;
                input.score = totalScore;
            } else {
                input.thisgamescore = 0;
            }

            // Update leaderboard
            await API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });

            // Update UI based on result
            setMessages(`You scored ${isCorrect ? timeval : 0} points.`);
            setIsShows(false);

            if (isCorrect) {
                setIsYourShowcorrects(true);
                fetchupdateData();
            } else {
                setIsShowcorrects(true);
                fetchupdatecorrectData();
            }
        } catch (err) {
            // Handle errors
            if (isCorrect) {
                setSubmittedmessage("For the best experience, please use a public browser and a stable internet connection.");
            } else {
                console.log(err);
            }
        }
    };
    const fetchupdatecorrectData = async () => {
        const input1 = {
            id: thisid,
            aimgurl: "wrong",
            answers: "submited"
        }
        await API.graphql({
            query: updateAnswerslist,
            variables: {input: input1}
        })
    }

    const fetchupdateData = async () => {
        const input2 = {
            id: thisid,
            aimgurl: "correct",
            answers: "submited"
        }
        await API.graphql({
            query: updateAnswerslist,
            variables: {input: input2}
        })
    }
    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        } else {
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }
    const getdatas = async () => {
        if (thisemail) {
            try {
                const thisqq = {
                    rid: rid,
                    question: quest,
                    correctanswer: "No answer",
                    thisscore: 0
                }
                let qq;
                if (userquestions) {
                    qq = [...userquestions, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: usertackingid,
                    name: thisname,
                    questions: qq,
                    thisgamescore: 0
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages("You scored 0 points")
            setIsShows(false);
            setIsShows(false);
        } else {
            setMessages("Time is up.")
            setIsShows(false);
        }
    }
    const fetchData = async () => {
        const response = await API.graphql({
            query: listAnswerslists,
            variables: {
                filter: {
                    "answerid": {
                        eq: `2${rid}`
                    }
                },
                limit: 10000
            }
        })

        let filteredData = response.data.listAnswerslists.items;
        const merged = mergeRecordsByEmail(filteredData);
        setPeoples(merged);
        setPeoplenumbers(merged.length);
        const countAnswered = merged.filter(record => record.answers === "submited").length;
        setAnswerednumbers(countAnswered);
        const countcorrctAnswered = merged.filter(record => record.answers === "submited");
        const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
        const iscorrectnumber = Number(countAnswered - iswrongnumber);
        setThisiscorrect(iscorrectnumber);
        setThisiswrong(iswrongnumber);
    }
    const mergeRecordsByEmail = (records) => {
        const mergedRecords = {};
        records.forEach(record => {
            const {email, answerid, answers, aimgurl} = record;
            if (!mergedRecords[email]) {
                mergedRecords[email] = {
                    email,
                    name: record.name,
                    answerid: null,
                    answers: null,
                    aimgurl: null
                };
            }
            if (answers === "submited") {
                mergedRecords[email].answerid = answerid;
                mergedRecords[email].answers = answers;
                mergedRecords[email].aimgurl = aimgurl;
            } else if (mergedRecords[email].answerid === null) {
                mergedRecords[email].answerid = answerid;
            }
        });

        // Return merged records as an array
        return Object.values(mergedRecords);
    };

    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <div className="fastanswerspage2">
                <div className="greenback">
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            {showclock ?
                                <div className="lpollheadclock2">
                                    <CountdownCircleTimer
                                        isPlaying={playpause}
                                        duration={counter}
                                        size={50}
                                        colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                        colorsTime={[7, 5, 2, 0]}
                                        onComplete={getdatas}
                                    >
                                        {renderTime}
                                    </CountdownCircleTimer>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <div className="fastanswes">
                                <h2>{quest}</h2>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="centerside">
                    <br/>
                    {isShowpeoples ?
                        <div className="peoplesview">
                            <img src={People} onClick={() => setShowPopup(true)}/>
                        </div>
                        : ""
                    }
                    {isopenimg ?
                        <img src={thisimg} className="fillinthimgshow"/>
                        : ""
                    }
                </div>
                {isShows ?
                    <>
                        <div>
                            <br/>
                            <br/>
                            <p className="biggertext">Your answer:</p>
                            <input type="text" onChange={handleChanges} maxLength="50" name="fanswer"
                                   className="fullline"
                                   placeholder="Type here"/>
                            <br/>
                        </div>
                        <br/>
                        <div className="fastbottom">
                            <div className="cccenter">
                                <p className="redthis"> {submittedmessage} </p>
                                <Button id="pollsubmit" onClick={psubmit}
                                        className="inputpolllist" disabled={isDisabled}>Submit</Button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {isShowcorrects ?
                            <div className="cccenter">
                                <br/>
                                <br/>
                                <p className="biggertext"> Your answer is <span className="redthis">{vals}</span></p>
                                <p className="biggertext"> The correct answer is <span
                                    className="redthis">{thiscorrect}</span></p>
                            </div>
                            : ""
                        }
                        {isYourShowcorrects ?
                            <div className="cccenter">
                                <br/>
                                <br/>
                                <p className="biggertext"> Great job! Your answer, <span
                                    className="redthis"> {vals}</span>, is correct. </p>
                            </div>
                            : ""
                        }
                        <div className="fastanswers">
                            <p className="pollmaeeage">{messages}</p>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </>
                }
            </div>
            <Modal isOpen={showPopup} ariaHideApp={false}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="rightside">
                            <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
                        </div>
                    </Grid>
                </Grid>
                <div className="cccenter">
                    <h3><img src={People}/> <span className="redthis">{peoplenumbers}</span> &nbsp; <img
                        src={Submitteds}/> <span className="redthis">{answerednumber}</span> &nbsp; <img
                        src={Correctimg} width="25px"/> <span className="redthis">{thisiscorrect}</span> &nbsp; <img
                        src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
                    </h3>
                    <br/>
                </div>
                <div>
                    <Table hover size="sm" className="sizefortable">
                        <thead>
                        <tr>
                            <th> Name</th>
                            <th className="cccenter"> Correct / Incorrect</th>
                        </tr>
                        </thead>
                        <tbody>
                        {peoples.map((record, index) => (
                            <tr
                                key={index}
                                style={{
                                    color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                                    marginBottom: '10px',
                                }} className="peopleviewsconitem">
                                <td className="leftsitethis">{record.name}</td>
                                <td>
                                    {record.answers === "submited" && record.aimgurl === "correct" ? (
                                        <img src={Correctimg} width="20px"/>
                                    ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                                        <img src={Wrongimg} width="16px"/>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </>
    )
}

export default Fasteranswerimgroom;

