import {API} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {useParams,useNavigate} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide, listHosts, listLeaderboards} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import moment from "moment";
import {
    createLeaderboard,
    updateLeaderboard
} from "../../graphql/mutations";
import Newwindow from "../../img/host/Layout.png";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";



function Vroom() {
    const [curl, setCurl] = useState("");
    const [vurl, setVurl] = useState("");
    const [lsize, setLsize] = useState(3);
    const [rsize, setRsize] = useState(9);
    const [yourusername, setYourusername] = useState('');
    const { id } = useParams();
    const history = useNavigate();
    const [showthis, setShowthis] = useState(true);
    const [usertrackingid, setUsertackingid] =useState("");
    sessionStorage.removeItem("reachumvideosilent");
    useEffect(() => {
        const fetchUserData = async () => {
            const username = localStorage.getItem("vreachumnewername");
            const userEmail = localStorage.getItem("vreachumneweremail");
            const updatedUsername = sessionStorage.getItem("updatedusername");
            const startTime = moment().format('MM/DD/YYYY hh:mm a');

            // Set username state
            setYourusername(username);

            // Handle missing email - redirect to form
            if (!userEmail) {
                history(`/vform/${id}`);
                return;
            }

            // Toggle UI element based on session storage
            if (updatedUsername) {
                setShowthis(false);
            }

            // Check if user has existing record
            try {
                const { data } = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: userEmail },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const existingRecords = data.listLeaderboards.items;

                if (existingRecords.length > 0) {
                    // Use existing record
                    const userTrackingId = existingRecords[0].id;
                    setUsertackingid(userTrackingId);
                    sessionStorage.setItem("edureachumthisid", userTrackingId);
                } else {
                    // Create new user tracking record
                    const newUserRecord = {
                        name: username,
                        meetemail: userEmail,
                        answerid: id,
                        score: 0,
                        status: "Remote",
                        thisgamescore: 0,
                        timerin: startTime
                    };

                    const response = await API.graphql({
                        query: createLeaderboard,
                        variables: { input: newUserRecord }
                    });

                    const newTrackingId = response.data.createLeaderboard.id;
                    setUsertackingid(newTrackingId);
                    sessionStorage.setItem("edureachumthisid", newTrackingId);
                }
            } catch (error) {
                console.error("Error managing user tracking data:", error);
                // Consider adding user-friendly error handling here
            }
        };

        fetchUserData();
    }, [id, history]);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            const vreachumnewerna = localStorage.getItem("vreachumnewername");
            const cc= `${datas.clientrlink}?embed&minimal&chat=on&screenshare=on&people=off&logo=on&displayName=${vreachumnewerna}`;
            setCurl(datas.clink);
            setVurl(cc);
            const fuls = datas.fulls;
            if(fuls === true){
                setLsize(12);
                setRsize(12)
            }else{
                setLsize(3);
                setRsize(9)
            }
            const user = datas.owner;
            API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: user
                        }
                    },
                    limit:10000
                }
            }).then(datas =>{
                const players = datas.data.listHosts.items;
                const sids = players[0].refer;
                if (sids === "Cancelled") {
                    history("/");
                }
            })
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const fuls = datas.fulls;
            if(fuls === true){
                setLsize(12);
                setRsize(12)
            }else{
                setLsize(3);
                setRsize(9)
            }
            const isplay = datas.vplaypause;
            if(isplay === true){
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
            }else{
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
            }
        }catch(error){
            console.log("hi")
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    const closeNavs = () => {
        document.getElementById("myNavs").style.width = "0%";
    }
    useHotkeys('/', () => {
        infobuttons()
    })
    async function ends(){
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };

    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Group Leader</title>
            </Helmet>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={lsize}>
                                {vurl !== "" && (
                                    <iframe
                                        className="viframe"
                                        id="leftsid"
                                        src={vurl}
                                        width="100%"
                                        height="100vh"
                                        title="clienturl"
                                        frameBorder="0"
                                        allow="camera; microphone; fullscreen; speaker; display-capture"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={rsize}>
                                {curl !== "" && (
                                    <iframe
                                        className="viframe"
                                        id="theFrame"
                                        src={curl}
                                        width="100%"
                                        height="100vh"
                                        frameBorder="0"
                                        allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className="bottoms">
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={7} className="leftside">
                                <div className="upgradenew3">
                                    <img src={Newwindow} onClick={infobuttons} className="viewbimg"/>
                                    <span className="tooltiptext3">Screen Layout (or / key)</span>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <img src={Ending} onClick={ends} className="viewbimg"/>
                            </Grid>
                        </Grid>
                    </div>
                    <div id="myNavs" className="overlay">
                        <div className="closefull">
                            <div className="upgradenew">
                                <p onClick={closeNavs}>Close</p>
                                <span className="tooltiptext">Click Close</span>
                            </div>
                        </div>
                        <iframe
                            className="tutorialiframe"
                            id="theFrame"
                            src={curl}
                            frameBorder="0"
                            allow="autoplay;fullscreen;payment"
                        />
                    </div>
            <Modal
                show={showthis}
                centered
                size="sm"
            >
                <Modal.Body>
                        <div className="cccenter">
                            <h4 className="greenselfthis">Welcome!</h4>
                            <br/>
                            <p className="pxsize">Display Name:</p>
                            <input type="text" className="centerthisinput" value={yourusername} name="message"
                                   onChange={handleChange222}/>
                            <br/>
                            <br/>
                            <Button  onClick={() => {
                                localStorage.setItem("vreachumnewername", yourusername);
                                setShowthis(false);
                                sessionStorage.setItem("updatedusername", "1")
                            }} variant="success">&nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;</Button>
                            <br/>
                            <br/>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Vroom;
