import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {API, Auth} from "aws-amplify";
import Foot from "../components/Foot";
import {Form, Row, Col, Button} from 'react-bootstrap';
import Navbars from "../components/Navbar";
import "./css/profile.css";
import Login from "./login";
import {Helmet} from "react-helmet";


const Error = ()  => {
    return(
        <>
            <Helmet>
                <title>Error</title>
            </Helmet>
            <Navbars />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="cccenter">
           <h2>Oops! It seems you don’t have an active plan.</h2>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

                    <Foot />
                </>

    )
}


export default Error;
