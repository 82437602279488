import {API} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {useParams,useNavigate} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide, listHosts, listLeaderboards} from "../../graphql/queries";
import {
    createLeaderboard,
    updateLeaderboard,
} from "../../graphql/mutations";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import moment from "moment";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";


function Iroom() {
    const [curl, setCurl] = useState("");
    const { id } = useParams();
    const [isOpen, setIsopen] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const history = useNavigate();
    const [showthis, setShowthis] = useState(true);
    const [usertrackingid, setUsertackingid] =useState("");
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [isValid, setIsValid] = useState(true);
    sessionStorage.setItem("reachumvideosilent", "true");
    useEffect(() => {
        // Get stored user data
        const username = localStorage.getItem("vreachumnewername");
        const userEmail = localStorage.getItem("vreachumneweremail");
        const hasUpdatedUsername = sessionStorage.getItem("updatedusername");
        const currentTime = moment().format('MM/DD/YYYY hh:mm a');

        // Update state based on stored data
        setYourusername(username);

        if (userEmail) {
            setIsopen(false);
        }

        if (hasUpdatedUsername) {
            setShowthis(false);
        }

        // Check if user exists and handle user tracking
        const checkUserAndTrack = async () => {
            if (!userEmail) return;

            try {
                // Query to check if user exists for this answer
                const resposes = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: userEmail },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const existingEntries = resposes.data.listLeaderboards.items;

                if (existingEntries.length > 0) {
                    const trackingId = existingEntries[0].id;
                    setUsertackingid(trackingId);
                    sessionStorage.setItem("edureachumthisid", trackingId);
                } else {
                    // Create new user tracking entry
                    const newUserData = {
                        name: username,
                        meetemail: userEmail,
                        answerid: id,
                        score: 0,
                        status: "In-Person",
                        thisgamescore: 0,
                        timerin: currentTime
                    };

                    const response = await API.graphql({
                        query: createLeaderboard,
                        variables: { input: newUserData }
                    });

                    const newTrackingId = response.data.createLeaderboard.id;
                    setUsertackingid(newTrackingId);
                    sessionStorage.setItem("edureachumthisid", newTrackingId);
                }
            } catch (error) {
                console.log("Error checking/creating user tracking:", error);
            }
        };

        checkUserAndTrack();
    }, [id]);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const user = datas.owner;
            API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: user
                        }
                    },
                    limit:10000
                }
            }).then(datas =>{
                const players = datas.data.listHosts.items;
                const sids = players[0].refer;
                if (sids === "Cancelled") {
                    history("/");
                }
            })
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            // Fetch data using GraphQL
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });

            const data = response.data.getPlayslide;
            setCurl(data.clink);

            // Handle auto play/pause timer functionality
            handleAutoPlayPause(data.aplaypause, data.clink);

            // Handle video play/pause state
            handleVideoPlayback(data.vplaypause);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

// Helper function to handle auto play/pause
    function handleAutoPlayPause(isAutoPlay, currentLink) {
        if (isAutoPlay) {
            const timerFrame = document.getElementById('theFrame');
            timerFrame.src = "/timer";
        } else {
            setCurl(currentLink);
        }
    }

// Helper function to handle video playback
    function handleVideoPlayback(isPlaying) {
        try {
            const theFrame = document.getElementById('theFrame');
            const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
            const reactPlayer = theFrameDocument.getElementById('reactplayer');

            if (reactPlayer) {
                reactPlayer.setAttribute("playing", isPlaying);
            }
        } catch (error) {
            console.error("Error setting video playback state:", error);
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    useHotkeys('/', () => {
        infobuttons()
    })
    async function ends(){
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
            try {
                const ssname = stateData.meetname;
                const ssemail = stateData.meetemail;
                localStorage.setItem("vreachumnewername", ssname);
                localStorage.setItem("vreachumneweremail", ssemail);
                setShowthis(false);
                sessionStorage.setItem("updatedusername", "1")
            } catch (err) {
                console.log(err)
            }
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };
    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {curl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    width="100%"
                                    height="100vh"
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className="bottoms">
                        <Grid item xs={7}>
                            <div className="whitethis">
                                <i>Screen blank, please tap.</i>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <img src={Ending} onClick={ends} className="viewbimg" alt="exit"/>
                        </Grid>
                    </Grid>
                </div>
            <Modal
                show={showthis}
                centered
                size="sm"
            >
                <Modal.Body>
                    <div className="cccenter">
                        <h4 className="greenselfthis">Welcome!</h4>
                        {isOpen ?
                            <>
                                <br/>
                                <p className="pxsize">Enter your email and a display name.<br/>
                                    All games are anonymous.<br/>
                                    Emails are used solely for reporting.</p>
                                <Form className="market-header">
                                    <Form.Item>
                                        <Input
                                            type="email"
                                            value={stateData.meetemail}
                                            onChange={meetemail => {
                                                setStateData({
                                                    ...stateData,
                                                    meetemail: meetemail
                                                });
                                                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                const isValidemail = emailRegex.test(meetemail);
                                                setIsValid(isValidemail);
                                            }}
                                            maxLength={100}
                                            placeholder="Email"
                                            className="craeteroomgame"
                                        />
                                        {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                    </Form.Item>
                                    <Form.Item>
                                        <Input
                                            type="text"
                                            value={stateData.meetname}
                                            onChange={meetname => setStateData({
                                                ...stateData,
                                                meetname: meetname
                                            })}
                                            maxLength={50}
                                            placeholder="Display Name"
                                            className="craeteroomgame"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            disabled={!stateData.meetemail || !stateData.meetname}
                                            onClick={handleSubmit2}
                                            variant="success"
                                        >
                                            &nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                            :
                            <div className="cccenter">
                                <br/>
                                <p className="pxsize">Display Name:</p>
                                <input type="text" className="centerthisinput" value={yourusername} name="message"
                                       onChange={handleChange222}/>
                                <br/>
                                <br/>
                                <Button variant="success"  onClick={() => {
                                    localStorage.setItem("vreachumnewername", yourusername);
                                    setShowthis(false);
                                    sessionStorage.setItem("updatedusername", "1")
                                }}>&nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;</Button>
                                <br/>
                                <br/>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Iroom;
