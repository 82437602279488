import React, {useState, useMemo, useEffect} from 'react';
import {API} from "aws-amplify";
import { updateAnswerslist, updateLeaderboard} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Thiswrong from "../../../img/view/close.png";
import Thiscorrect from "../../../img/view/check-mark.png";
import moment from "moment";
import People from "../../../img/overview/people.png";
import Submitteds from "../../../img/overview/submit.png"
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import {listAnswerslists} from "../../../graphql/queries";
import Close from "../../../img/host/close.png";
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";
import Modal from "react-modal";


function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showitemlist() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const {id, rid} = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [isshowreset, setIsshowreset] = useState(false);
  const [playpause, setPlaypause] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState();
  const [submittedmessage, setSubmittedmessage] = useState("");
  const [showclock, setShowclock] = useState(false);
  const [usertackingid, setUsertackingid] = useState("");
  const [usertotascore, setUsertotascore] = useState("");
  const [isShowpeoples, setIsShowpeoples] = useState(false);
  const [peoplenumbers, setPeoplenumbers] = useState(0);
  const [peoples, setPeoples] = useState([]);
  const [answerednumber, setAnswerednumbers] = useState(0);
  const [thispeopleid, setThispeopleid] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [thisiscorrect, setThisiscorrect] = useState(0);
  const [thisiswrong, setThisiswrong] = useState(0);
  const [thisname, setThisname] = useState(localStorage.getItem('vreachumnewername') || '');
  const [thisemail, setThisemail] = useState(localStorage.getItem('vreachumneweremail')||'');
  sessionStorage.setItem("edureachumthisrid", rid)
  useEffect(() => {
    const newCards = sessionStorage.getItem("sequencinggamesques");
    setQuest(newCards);
    const thisid = sessionStorage.getItem("edureachumthisid");
    setUsertackingid(thisid);
    const thisscore = sessionStorage.getItem("edureachumthistotalscore");
    setUsertotascore(thisscore);
    const newcounter = Number(sessionStorage.getItem("sequencinggamescounter"));
    if (!thisname) {
      setIsShowpeoples(true)
    }
    const thispid = sessionStorage.getItem("thisflipshowroomreachumid");
    setThispeopleid(thispid)
    if (newcounter !== 0) {
      setCounter(newcounter);
      setShowclock(true);
    } else {
      setTimeval(40)
    }
    const submitrids = sessionStorage.getItem("submitrid");
    if (submitrids) {
      setThissaved(submitrids)
      if (submitrids.includes(rid)) {
        setDisabled(true);
        setSubmittedmessage("Submitted")
      }
    }
  }, []);
  const storedItems = useMemo(() => {
    const stored = sessionStorage.getItem('sequencinggames');
    if (stored == null) {
      return []
    }
    return JSON.parse(stored)
  }, [])
  const [items, setItems] = useState(() => {
    return shuffle(storedItems)
  })
  const defaultAnswers = items.map(() => {
    return {
      id: '',
      text: '',
      image: ''
    }
  })

  const renderTime = ({remainingTime}) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    } else {
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [activeItem, setActiveItem] = useState()

  const [answers, setAnswers] = useState(() => {
    return defaultAnswers
  })
  const answerViews = answers.map((answer, answerIndex) => {
    function handleClick() {
      if (activeItem == null) {
        return
      }
      setAnswers(currentAnswers => {
        const newAnswers = currentAnswers.map((currentAnswer, currentAnswerIndex) => {
          if (answerIndex === currentAnswerIndex) {
            return activeItem
          }
          return currentAnswer
        })
        return newAnswers
      })
      setActiveItem(undefined)
    }

    const key = answer.id !== '' ? answer.id : answerIndex
    const image = answer.image !== '' && (
        <img src={answer.image} style={{width: '170px'}}/>
    )
    const correctIcon = answer.correct === true && (
        <img className="correctandincorrect" src={Thiscorrect} width="20px"/>)
    const incorrectIcon = answer.correct === false && (
        <img className="correctandincorrect" src={Thiswrong} width="20px"/>)
    const correctAnswer = answer.correctAnswer != null && (
        <div style={{marginTop: "8px"}}><span style={{color: "#269b7b"}}> {answer.correctAnswer.text} </span> <img
            src={answer.correctAnswer.image} style={{width: '100px'}}/></div>
    )
    return (
        <>
          <li key={key} onClick={handleClick} style={{
            fontSize: "20px",
            marginLeft: "8px",
            marginBottom: "8px",
            backgroundColor: "#f0f0f0",
            color: "#0c4128",
            padding: "5px"
          }}>
            <div className="centerthis">
              <>{correctIcon} {incorrectIcon}</>
              <span style={{marginLeft: "5px"}}>{answer.text}</span> &nbsp; &nbsp;
              {image}
              <br/>
              {correctAnswer}
            </div>
          </li>
        </>
    )
  })
  const remainingItems = items.filter(item => {
    const answered = answers.some(answer => answer.id === item.id)
    return !answered
  })
  const remainingViews = remainingItems.map(item => {
    function handleClick() {
      setActiveItem(item);
      setIsshowreset(true)
    }

    const isActive = activeItem != null && activeItem.id === item.id;
    const style = {
      margin: '5px',
      padding: '10px',
      fontSize: "20px",
      fontFamily: "forma-djr-micro, sans-serif",
      border: "1px solid #d2196e",
      borderRadius: "10px"
    }
    if (isActive) {
      style.background = '#72b5a4';
      style.color = "#ffffff";
      style.borderRadius = "8px";
      style.transform = "scale(1.5, 1.5)"
    }
    const image = item.image !== '' && (
        <img src={item.image} style={{width: '170px'}}/>
    )
    return (
        <div
            key={item.id}
            style={style}
            onClick={handleClick}
        >
          <span>{item.text}</span><br/>
          {image}
        </div>
    )
  })

  function handleReset() {
    setAnswers(defaultAnswers);
    setIsshowreset(false)
  }

  useEffect(() => {
    fetchData();
    const createanswerslist = API.graphql(
        {
          query: newCreateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const updateanswerslist = API.graphql(
        {
          query: newUpdateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const deleteanswerslist = API.graphql(
        {
          query: newDeleteAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    return () => {
      createanswerslist.unsubscribe();
      updateanswerslist.unsubscribe();
      deleteanswerslist.unsubscribe()
    }
  }, []);

  function handleSubmit() {
    // Calculate correct answers and score
    const corrects = answers.filter((answer, answerIndex) =>
        storedItems[answerIndex].id === answer.id
    );

    const correctCount = corrects.length;
    const totalItems = items.length;
    const completionPercentage = Math.round((correctCount / totalItems) * 100) + "%";

    // Store submission ID in session storage
    const submissionIds = thissaved ? [thissaved, rid] : [rid];
    sessionStorage.setItem("submitrid", submissionIds);

    // Stop timer/animation
    setPlaypause(false);

    // Calculate score
    const rawScore = timeval * correctCount;
    const score = Math.floor(rawScore / totalItems);

    // Get session data
    const questionText = sessionStorage.getItem("edureachumthisquestion");
    const startTime = sessionStorage.getItem("edureachumthisstarttimer");
    const savedQuestions = sessionStorage.getItem("edureachumthisquessys");

    // Calculate time taken
    const endTime = new Date();
    const timeDiff = endTime - new Date(startTime);
    const seconds = parseInt((timeDiff / 1000) % 60);

    // Prepare question result
    const questionResult = {
      rid,
      question: questionText,
      correctanswer: score > 0 ? "correct" : "wrong",
      thisscore: score > 0 ? score : 0,
      duration: seconds
    };

    // Update questions array
    let updatedQuestions;
    if (savedQuestions) {
      updatedQuestions = [...JSON.parse(savedQuestions), questionResult];
    } else {
      updatedQuestions = [questionResult];
    }

    // Create leaderboard update input
    const leaderboardInput = {
      id: usertackingid,
      name: thisname,
      questions: updatedQuestions,
      thisgamescore: score > 0 ? score : 0
    };

    // Add total score if correct answer
    if (score > 0) {
      leaderboardInput.score = score + Number(usertotascore);
    }

    // Update the leaderboard
    try {
      // Update leaderboard
      API.graphql({
        query: updateLeaderboard,
        variables: { input: leaderboardInput }
      });

      // Update answer list if score > 0
      if (score > 0) {
        try {
          const answerlistInput = {
            id: thispeopleid,
            aimgurl: completionPercentage,
            answers: "submited"
          };

          API.graphql({
            query: updateAnswerslist,
            variables: { input: answerlistInput }
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        fetchupdatecorrectData();
      }

      // Set message based on score
      setMessages(`You scored ${score > 0 ? score : 0} points.`);
    } catch (err) {
      if (score > 0) {
        setSubmittedmessage("For the best experience, please use a public browser and a stable internet connection.");
      } else {
        console.log(err);
      }
    }

    // Mark correct and incorrect answers
    const newAnswers = answers.map((currentAnswer, index) => {
      const correctAnswer = storedItems[index];
      const answeredCorrect = currentAnswer.text === correctAnswer.text;

      return {
        ...currentAnswer,
        correct: answeredCorrect,
        ...(!answeredCorrect && { correctAnswer })
      };
    });

    // Update UI
    setAnswers(newAnswers);
    setIsShows(false);
  }

  const fetchupdatecorrectData = async () => {
    const input1 = {
      id: thispeopleid,
      aimgurl: "wrong",
      answers: "submited"
    }
    await API.graphql({
      query: updateAnswerslist,
      variables: {input: input1}
    })
  }

  const getdatas = async () => {
    if (thisemail) {
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const thisqueslist = sessionStorage.getItem("edureachumthisquessys");
      try {
        const thisqq = {
          rid: rid,
          question: thisques,
          correctanswer: "No answer",
          thisscore: 0
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: usertackingid,
          name: thisname,
          questions: qq,
          thisgamescore: 0
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.")
      setIsShows(false);
    } else {
      setMessages("Time is up.")
      setIsShows(false);
    }
  }
  const fetchData = async () => {
    const response = await API.graphql({
      query: listAnswerslists,
      variables: {
        filter: {
          "answerid": {
            eq: `2${rid}`
          }
        },
        limit: 10000
      }
    })

    let filteredData = response.data.listAnswerslists.items;
    const merged = mergeRecordsByEmail(filteredData);
    setPeoples(merged);
    setPeoplenumbers(merged.length);
    const countAnswered = merged.filter(record => record.answers === "submited").length;
    setAnswerednumbers(countAnswered);
    const countcorrctAnswered = merged.filter(record => record.answers === "submited");
    const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
    const iscorrectnumber = Number(countAnswered - iswrongnumber);
    setThisiscorrect(iscorrectnumber);
    setThisiswrong(iswrongnumber);
  }
  const mergeRecordsByEmail = (records) => {
    const mergedRecords = {};
    records.forEach(record => {
      const {email, answerid, answers, aimgurl} = record;
      if (!mergedRecords[email]) {
        mergedRecords[email] = {
          email,
          name: record.name,
          answerid: null,
          answers: null,
          aimgurl: null
        };
      }
      if (answers === "submited") {
        mergedRecords[email].answerid = answerid;
        mergedRecords[email].answers = answers;
        mergedRecords[email].aimgurl = aimgurl;
      } else if (mergedRecords[email].answerid === null) {
        mergedRecords[email].answerid = answerid;
      }
    });

    // Return merged records as an array
    return Object.values(mergedRecords);
  };

  useEffect(() => {
    let isUpdating = false;
    const onClose = async () => {
      if (isUpdating) return;
      isUpdating = true;
      const thisid = sessionStorage.getItem("edureachumthisid");
      if (!thisid) {
        isUpdating = false;
        return;
      }
      const ends = moment().format('MM/DD/YYYY hh:mm a');
      try {
        const input = {
          id: thisid,
          timerout: ends
        };

        await API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.error("Error updating leaderboard:", err);
      } finally {
        setTimeout(() => {
          isUpdating = false;
        }, 1000);
      }
    };
    window.addEventListener('beforeunload', onClose);
    window.addEventListener('pagehide', onClose);
    return () => {
      onClose();
      window.removeEventListener('beforeunload', onClose);
      window.removeEventListener('pagehide', onClose);
    };
  }, []);
  return (
      <>
        <div className="fastanswerspage2">
          <div className="greenback">
            <Grid container spacing={0}>
              <Grid item xs={2}>
                {showclock ?
                    <div className="lpollheadclock2">
                      <CountdownCircleTimer
                          isPlaying={playpause}
                          duration={counter}
                          size={50}
                          colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                          colorsTime={[10, 6, 3, 0]}
                          onComplete={getdatas}
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                    : ""
                }
              </Grid>
              <Grid item xs={10}>
                <div className="fastanswes">
                  <h2>{quest}</h2>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            {isShowpeoples ?
                <div className="peoplesview">
                  <img src={People} onClick={() => setShowPopup(true)}/>
                </div>
                : ""
            }
          </div>
          <br/>
          <>
            {isShows ?
                <div style={{
                  display: 'flex',
                  flexWrap: "wrap",
                  width: "100%",
                  margin: "0 auto",
                  padding: "10px",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                  justifyContent: "center"
                }}>
                  {remainingViews}
                </div>
                : ""
            }
            <br/>
            <br/>
            <ol style={{textAlign: "center", margin: "10px", width: "98%"}}>
              {answerViews}
            </ol>
            <br/>
            {isShows ?
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={5} className="wordsleftside">
                      {isshowreset ?
                          <Button onClick={handleReset} variant="danger">Reset</Button>
                          : ""
                      }
                    </Grid>
                    <Grid item xs={5} className="rightside">
                      <Grid item xs={12}>
                        <p className="redthis"> {submittedmessage} </p>
                        <Button onClick={handleSubmit} variant="success"
                                disabled={isDisabled}>Submit</Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                  </Grid>
                  <br/>
                  <br/>
                  <br/>
                </> :
                <>
                  <div className="cccenter">
                    <br/>
                    <p className="pollmaeeage">{messages}</p>
                    <br/>
                    <br/>
                    <br/>
                  </div>
                </>
            }
          </>
        </div>
        <Modal isOpen={showPopup} ariaHideApp={false}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="leftside">
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="rightside">
                <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
              </div>
            </Grid>
          </Grid>
          <div className="cccenter">
            <h3><img src={People}/> <span className="redthis">{peoplenumbers}</span> &nbsp; <img src={Submitteds}/>
              <span className="redthis">{answerednumber}</span> &nbsp; <img
                  src={Correctimg} width="25px"/> <span className="redthis">{thisiscorrect}</span> &nbsp; <img
                  src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
            </h3>
            <br/>
          </div>
          <div>
            <Table hover size="sm" className="sizeforbtable">
              <thead>
              <tr>
                <th> Name</th>
                <th className="cccenter"> Correct / Incorrect</th>
                <th className="cccenter"> % Correct</th>
              </tr>
              </thead>
              <tbody>
              {peoples.map((record, index) => (
                  <tr
                      key={index}
                      style={{
                        color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                        marginBottom: '10px',
                      }} className="peopleviewsconitem">
                    <td className="leftsitethis">{record.name}</td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <img src={Correctimg} width="20px"/>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <img src={Wrongimg} width="16px"/>
                      ) : (
                          <span></span>
                      )}
                    </td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <span>{record.aimgurl}</span>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <span>0%</span>
                      ) : (
                          <span></span>
                      )}
                    </td>
                  </tr>
              ))}
              </tbody>
            </Table>
          </div>
        </Modal>
      </>
  )
}
