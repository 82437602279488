import {API} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getPlayslide, listHosts, listLeaderboards} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import "../css/room.css";
import {
    createLeaderboard,
    updateLeaderboard
} from "../../graphql/mutations";
import moment from "moment";
import {Helmet} from "react-helmet";
import Ending from "../../img/view/exit.svg";
import Content from "../../img/view/content.svg";
import People from "../../img/view/people.svg";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import ReactSwipe from 'react-swipe';

function Mcroom() {
    const [curl, setCurl] = useState("");
    const [vurl, setVurl] = useState("");
    const [isopenthiscontent, setIsopenthiscontent] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const { id } = useParams();
    const history = useNavigate();
    const [showthis, setShowthis] = useState(true);
    const [usertrackingid, setUsertackingid] =useState("");
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    sessionStorage.removeItem("reachumvideosilent")
    function getCurrentDimension(){
        return {
            height: window.innerHeight
        }
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    let reactSwipeEl;
    useEffect(() => {
        // Get stored user data
        const username = localStorage.getItem("vreachumnewername");
        const userEmail = localStorage.getItem("vreachumneweremail");
        const hasUpdatedUsername = sessionStorage.getItem("updatedusername");
        const currentTime = moment().format('MM/DD/YYYY hh:mm a');

        // Update state based on stored data
        setYourusername(username);

        if (!userEmail) {
            history(`/rform/${id}`);
            return;
        }

        if (hasUpdatedUsername) {
            setShowthis(false);
        }

        // Check if user exists and handle user tracking
        const checkUserAndTrack = async () => {
            if (!userEmail) return;

            try {
                // Query to check if user exists for this answer
                const resposes = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: userEmail },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const existingEntries = resposes.data.listLeaderboards.items;

                if (existingEntries.length > 0) {
                    const trackingId = existingEntries[0].id;
                    setUsertackingid(trackingId);
                    sessionStorage.setItem("edureachumthisid", trackingId);
                } else {
                    // Create new user tracking entry
                    const newUserData = {
                        name: username,
                        meetemail: userEmail,
                        answerid: id,
                        score: 0,
                        status:"Remote-Mobile",
                        thisgamescore: 0,
                        timerin: currentTime
                    };

                    const response = await API.graphql({
                        query: createLeaderboard,
                        variables: { input: newUserData }
                    });

                    const newTrackingId = response.data.createLeaderboard.id;
                    setUsertackingid(newTrackingId);
                    sessionStorage.setItem("edureachumthisid", newTrackingId);
                }
            } catch (error) {
                console.log("Error checking/creating user tracking:", error);
            }
        };

        checkUserAndTrack();
    }, [id, history]);

    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        async function fetchData() {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id}
                })
                const datas = response.data.getPlayslide;
                setCurl(datas.clink);
                const v = datas.aplaypause;
                if(v === true){
                    const thetimer = document.getElementById('theFrame');
                    thetimer.src =  "/timer";
                }else{
                    setCurl(datas.clink);
                }
                const isplay = datas.vplaypause;
                if(isplay === true){
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
                }else{
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
                }
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            const vreachumnewerna = localStorage.getItem("vreachumnewername");
            const cc= `${datas.clientrlink}?embed&minimal&chat=on&people=off&logo=on&displayName=${vreachumnewerna}`;
            setCurl(datas.clink)
            setVurl(cc);
            const user = datas.owner;
            API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: user
                        }
                    },
                    limit:10000
                }
            }).then(datas =>{
                const players = datas.data.listHosts.items;
                const sids = players[0].refer;
                if (sids === "Cancelled") {
                    history("/");
                }
            })
        }catch(error){
            console.log("Cannot display")
        }
    }

    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };

    async function ends(){
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <>
                    <div className="thisfullpage">
                        <ReactSwipe
                            className="carousel"
                            swipeOptions={{ continuous: true }}
                            ref={el => (reactSwipeEl = el)}
                        >
                <div>
                        {vurl !== "" && (
                                <iframe
                                    className="fullpage"
                                    id="leftsid"
                                    src={vurl}
                                    loading="lazy"
                                    height={screenSize.height}
                                    title="clienturl"
                                    frameBorder="0"
                                    allow="camera; microphone; fullscreen; speaker; display-capture"
                                />
                            )}
                </div>
                            <div>
                            {curl !== "" && (
                                <iframe
                                    className="fullpage"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    height={screenSize.height}
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                </div>
                        </ReactSwipe>
                    </div>
                    {isopenthiscontent ?
                        <img src={Content} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(false)}} width="55" className="bottom" alt="content" />
                        :
                        <img src={People} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(true)}} width="55" className="bottom" alt="people"/>
                    }
                    <img src={Ending} onClick={ends} className="thismobileexit" alt="exit"/>
                    <Modal
                        show={showthis}
                        centered
                        size="sm"
                    >
                        <Modal.Body>
                            <div className="enteryournameform">
                                <div className="cccenter">
                                    <h4 className="greenselfthis">Welcome!</h4>
                                    <br/>
                                    <p className="pxsize">Display Name:</p>
                                    <input type="text" className="centerthisinput" value={yourusername} name="message"
                                           onChange={handleChange222}/>
                                    <br/>
                                    <br/>
                                    <Button  onClick={() => {
                                        localStorage.setItem("vreachumnewername", yourusername);
                                        setShowthis(false);
                                        sessionStorage.setItem("updatedusername", "1")
                                    }} variant="success">&nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;</Button>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    </>
            </>
    );
}
export default Mcroom;
