import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import "../../css/leaderboard.css";
var XLSX = require("xlsx");

function Cdleaderboard(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
    }, []);

    useEffect(() => {
        fetchDas();
    }, [id]);

    async function fetchDas() {
        const scounts = [];
        let nextToken = null;

        try {
            do {
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 1000,
                        nextToken: nextToken
                    }
                });

                const datas = response.data.listLeaderboards.items;
                nextToken = response.data.listLeaderboards.nextToken;

                // Add items from this batch to our collection
                datas.forEach((data) => {
                    scounts.push(data);
                });

            } while (nextToken);
            scounts.sort((a, b) => b.score - a.score);
            const dcounts = [...scounts];
            setMyData(dcounts);
        } catch (err) {
            console.error("Error fetching data:", err);
        } finally {

        }
    }

    const convertToCSV = (array) => {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => Object.values(obj).join(','));
        return [header, ...rows].join('\n');
    };

    const downloadFile = () => {
        const mynewdata = myData.map(row => ({
            name: row.name,
            email: row.meetemail,
            score: row.score
        }));
        const csvContent = convertToCSV(mynewdata);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${roomname} - leaderboard.csv`;
        link.click();
    };

    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadFile} className="savespread">Download</button>
                                </div>
                        </div>
                    </>
    )
}

export default Cdleaderboard;
