import React, { useEffect, useState, useCallback } from "react";
import { API, Auth } from 'aws-amplify';
import { getAnswers, getPlayslide, listAnswerslists } from '../../graphql/queries';
import { createAnswerslist, deleteAnswerslist } from '../../graphql/mutations';
import { newDeleteAnswerslist, newCreateAnswerslist } from '../../graphql/subscriptions';
import { useParams } from "react-router-dom";
import ReactWordcloud from 'react-wordcloud';
import * as XLSX from "xlsx";

// Components
import { Button, ListGroup } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DropdownButton from 'react-bootstrap/DropdownButton';

// Styles
import "../css/answers.css";

// Wordcloud configuration options
const WORDCLOUD_OPTIONS = {
    colors: ['#e82c82', '#034f4b', '#01312e', '#311d48', '#000000', '#595959', '#a574dc', '#269b7b', '#72b5a4', '#d2196e', '#580b3f'],
    enableTooltip: false,
    deterministic: false,
    fontFamily: ['impact', 'Helvetica', 'sans-serif', 'Tahoma', 'Verdana', 'Courier New'],
    fontSizes: [15, 70],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [-90, 0, 90],
    scale: 'sqrt',
    spiral: 'rectangular',
    transitionDuration: 1000,
};

/**
 * Wordcloudroom Component - Interactive word cloud for audience responses
 */
function Wordcloudroom() {
    // State declarations
    const [isHost, setIsHost] = useState(false);
    const [showInput1, setShowInput1] = useState(true);
    const [showInput2, setShowInput2] = useState(true);
    const [showInput3, setShowInput3] = useState(true);
    const [wordCloudData, setWordCloudData] = useState([]);
    const [question, setQuestion] = useState("");
    const [input1, setInput1] = useState("");
    const [input2, setInput2] = useState("");
    const [input3, setInput3] = useState("");
    const [isSubmitDisabled1, setIsSubmitDisabled1] = useState(true);
    const [isSubmitDisabled2, setIsSubmitDisabled2] = useState(true);
    const [isSubmitDisabled3, setIsSubmitDisabled3] = useState(true);
    const [statusMessage, setStatusMessage] = useState("Waiting for responses ...");

    // Hooks
    const { id, rid } = useParams();

    // Store room ID in session storage
    useEffect(() => {
        sessionStorage.setItem("edureachumthisrid", rid);
    }, [rid]);

    // Initial data fetching and authentication
    useEffect(() => {
        const initializeComponent = async () => {
            try {
                // Authenticate user
                const currentUser = await Auth.currentAuthenticatedUser();
                sessionStorage.setItem("answerowner", currentUser.attributes.email);
            } catch (error) {
                console.error("Authentication error:", error);
            }

            // Fetch owner and question data
            await fetchOwnerData();
            await fetchQuestionData();
        };

        initializeComponent();
    }, []);

    // Set up real-time updates
    useEffect(() => {
        fetchResponsesData();

        // Subscribe to changes in responses
        const deleteSubscription = API.graphql({
            query: newDeleteAnswerslist
        }).subscribe({
            next: () => fetchResponsesData()
        });

        const createSubscription = API.graphql({
            query: newCreateAnswerslist
        }).subscribe({
            next: () => fetchResponsesData()
        });

        // Cleanup subscriptions
        return () => {
            deleteSubscription.unsubscribe();
            createSubscription.unsubscribe();
        };
    }, []);

    // Fetch question data
    const fetchQuestionData = async () => {
        try {
            const response = await API.graphql({
                query: getAnswers,
                variables: { id: rid }
            });

            const data = response.data.getAnswers;
            const questionText = data.fquestion;

            setQuestion(questionText);
            sessionStorage.setItem("edureachumthisquestion", questionText);
        } catch (error) {
            console.error("Error fetching question data:", error);
        }
    };

    // Check if current user is the host
    const fetchOwnerData = async () => {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });

            const data = response.data.getPlayslide;
            const owner = data.owner;
            const currentUser = sessionStorage.getItem("answerowner");

            setIsHost(currentUser === owner);
        } catch (error) {
            console.error("Error checking host status:", error);
        }
    };

    // Generate random size for word cloud words
    const getRandomSize = () => {
        return Math.floor(Math.random() * 1000) + 100;
    };

    // Fetch responses data
    const fetchResponsesData = async () => {
        try {
            const response = await API.graphql({
                query: listAnswerslists,
                variables: {
                    filter: {
                        answerid: { eq: rid }
                    }
                }
            });

            const data = response.data.listAnswerslists;
            const items = data.items;

            if (items.length > 0) {
                // Format data for word cloud
                const formattedData = items.map(item => ({
                    ...item,
                    text: item.answers,
                    value: getRandomSize()
                }));

                // Remove original answers property
                formattedData.forEach(item => delete item.answers);

                setWordCloudData(formattedData);
                setStatusMessage("");
            } else {
                setWordCloudData([]);
                setStatusMessage("Waiting for responses ...");
            }
        } catch (error) {
            console.error("Error fetching responses:", error);
        }
    };

    // Export responses to Excel file
    const exportToExcel = useCallback(() => {
        // Format data for Excel export
        const rows = wordCloudData.map(row => ({
            name: row.name,
            email: row.email,
            answer: row.text
        }));

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();

        // Add headers
        XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Email", "Answer"]], { origin: "A1" });

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");

        // Write file
        XLSX.writeFile(workbook, `${question} - REACHUM_wordcloud.xlsx`, { compression: true });
    }, [wordCloudData, question]);

    // Clear all responses
    const clearAllResponses = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete all answers? \nEither OK or Cancel.");

        if (confirmDelete) {
            try {
                const totalResponses = wordCloudData.length;

                for (let i = 0; i < totalResponses; i++) {
                    const input = {
                        id: wordCloudData[i].id
                    };

                    await API.graphql({
                        query: deleteAnswerslist,
                        variables: { input }
                    });
                }
            } catch (error) {
                console.error("Error deleting responses:", error);
            }
        }
    };

    // Submit handlers for each input
    const handleSubmit1 = async () => {
        try {
            const username = localStorage.getItem("vreachumnewername");
            const userEmail = localStorage.getItem("vreachumneweremail");

            const input = {
                answerid: rid,
                answers: input1,
                name: username,
                email: userEmail,
            };

            await API.graphql({
                query: createAnswerslist,
                variables: { input }
            });

            setInput1("");
            setIsSubmitDisabled1(true);
            setShowInput1(false);
        } catch (error) {
            console.error("Error submitting response:", error);
        }
    };

    const handleSubmit2 = async () => {
        try {
            const username = localStorage.getItem("vreachumnewername");
            const userEmail = localStorage.getItem("vreachumneweremail");

            const input = {
                answerid: rid,
                answers: input2,
                name: username,
                email: userEmail,
            };

            await API.graphql({
                query: createAnswerslist,
                variables: { input }
            });

            setInput2("");
            setIsSubmitDisabled2(true);
            setShowInput2(false);
        } catch (error) {
            console.error("Error submitting response:", error);
        }
    };

    const handleSubmit3 = async () => {
        try {
            const username = localStorage.getItem("vreachumnewername");
            const userEmail = localStorage.getItem("vreachumneweremail");

            const input = {
                answerid: rid,
                answers: input3,
                name: username,
                email: userEmail,
            };

            await API.graphql({
                query: createAnswerslist,
                variables: { input }
            });

            setInput3("");
            setIsSubmitDisabled3(true);
            setShowInput3(false);
        } catch (error) {
            console.error("Error submitting response:", error);
        }
    };

    // Input change handlers
    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setInput1(value);
        setIsSubmitDisabled1(value === "");
    };

    const handleInputChange2 = (e) => {
        const value = e.target.value;
        setInput2(value);
        setIsSubmitDisabled2(value === "");
    };

    const handleInputChange3 = (e) => {
        const value = e.target.value;
        setInput3(value);
        setIsSubmitDisabled3(value === "");
    };

    return (
        <div className="answersshowroompage">
            <div className="answersroom">
                <div className="purple1back">
                    <h2>{question}</h2>
                </div>

                {!isHost ? (
                    // Participant view
                    <>
                        <div>
                            <br />
                            <br />

                            {/* First input field */}
                            {showInput1 && (
                                <div>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7} className="rightside">
                                            <input
                                                onChange={handleInputChange1}
                                                maxLength="35"
                                                placeholder="Type Word Here"
                                                value={input1}
                                                className="wordcloudinput"
                                            />
                                        </Grid>
                                        <Grid item xs={5} className="wordsleftside">
                                            &nbsp; &nbsp;
                                            <Button
                                                onClick={handleSubmit1}
                                                className="convosubmit"
                                                disabled={isSubmitDisabled1}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <br />
                                </div>
                            )}

                            {/* Second input field */}
                            {showInput2 && (
                                <div>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7} className="rightside">
                                            <input
                                                onChange={handleInputChange2}
                                                maxLength="35"
                                                placeholder="Type Word Here (Option)"
                                                value={input2}
                                                className="wordcloudinput"
                                            />
                                        </Grid>
                                        <Grid item xs={5} className="wordsleftside">
                                            &nbsp; &nbsp;
                                            <Button
                                                onClick={handleSubmit2}
                                                className="convosubmit"
                                                disabled={isSubmitDisabled2}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <br />
                                </div>
                            )}

                            {/* Third input field */}
                            {showInput3 && (
                                <div>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7} className="rightside">
                                            <input
                                                onChange={handleInputChange3}
                                                maxLength="35"
                                                placeholder="Type Word Here (Option)"
                                                value={input3}
                                                className="wordcloudinput"
                                            />
                                        </Grid>
                                        <Grid item xs={5} className="wordsleftside">
                                            &nbsp; &nbsp;
                                            <Button
                                                onClick={handleSubmit3}
                                                className="convosubmit"
                                                disabled={isSubmitDisabled3}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>

                        {/* Word cloud visualization */}
                        <div className="cccenter">
                            <br />
                            <ReactWordcloud words={wordCloudData} options={WORDCLOUD_OPTIONS} />
                        </div>
                    </>
                ) : (
                    // Host view
                    <>
                        <div className="anserrightside">
                            <div className="answershowleftside">
                                <DropdownButton id="dropdown-basic-button" className="rdropmenu" title="">
                                    <ListGroup>
                                        <ListGroup.Item>
                      <span onClick={exportToExcel} className="answersspan">
                        Save as Spreadsheet
                      </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                      <span onClick={clearAllResponses} className="answersspan">
                        Delete Responses
                      </span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </DropdownButton>
                            </div>

                            <div className="cccenter">
                                {statusMessage && (
                                    <div className="answersroomwaiting">
                                        <br />
                                        <br />
                                        <h1>{statusMessage}</h1>
                                    </div>
                                )}
                                <ReactWordcloud words={wordCloudData} options={WORDCLOUD_OPTIONS} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Wordcloudroom;

