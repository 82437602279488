import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import writeXlsx from 'write-excel-file';

function Cdlessonlog(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
    }, []);

    useEffect(() => {
        fetchDas();
    }, [id]);

    async function fetchDas() {
        let allItems = [];
        let nextToken = null;

        try {
            // Fetch all pages of data
            do {
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 1000, // More reasonable limit per request
                        nextToken: nextToken
                    }
                });

                const items = response.data.listLeaderboards.items;
                nextToken = response.data.listLeaderboards.nextToken;
                allItems = [...allItems, ...items];

            } while (nextToken);
            // Sort by score (descending)
            const sortedItems = [...allItems].sort((a, b) => b.score - a.score);

            // Process items to calculate total duration
            const processedItems = sortedItems.map(item => {
                const totalDuration = item.questions
                    ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                    : 0;
                return {
                    ...item,
                    dura: totalDuration,
                };
            });

            setMyData(processedItems);
        } catch (err) {
            console.error("Error fetching data:", err);
        } finally {

        }
    }
    const calculateDuration = (start, end) => {
        if (!start || !end) return "";

        const startDate = new Date(start);
        const endDate = new Date(end);
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            return "";
        }

        const diffMs = endDate - startDate; // Milliseconds difference

        if (diffMs < 0) return "";

        const diffMins = Math.floor(diffMs / 60000); // Convert to minutes
        const hours = Math.floor(diffMins / 60);
        const minutes = diffMins % 60;

        return `${hours}h ${minutes}m`;
    };


    const downloadlessonFile = async () => {

        const maxQuestions = Math.max(
            ...myData.map(item => (item.questions ? item.questions.length : 0))
        );


        const headers = [
            { value: 'Email', fontWeight: 'bold' },
            { value: 'Name', fontWeight: 'bold' },
            { value: 'Total Score', fontWeight: 'bold' },
            { value: 'Total Dur', fontWeight: 'bold' },
            { value: 'Learning Mode', fontWeight: 'bold' }
        ];

        // Add headers for each question
        for (let i = 1; i <= maxQuestions; i++) {
            headers.push({ value: `Q${i} Score`, fontWeight: 'bold' });
            headers.push({ value: `Q${i} Dur(Sec)`, fontWeight: 'bold' });
        }

        headers.push({ value: 'Timer In', fontWeight: 'bold' });
        headers.push({ value: 'Timer Out', fontWeight: 'bold' });
        headers.push({ value: 'Education Level', fontWeight: 'bold' });
        headers.push({ value: 'Source', fontWeight: 'bold' });

        const rows = [headers];

        // Populate rows with data
        myData.forEach(item => {
            const totalDuration = calculateDuration(item.timerin, item.timerout);
            const row = [
                { value: item.meetemail },
                { value: item.name },
                { value: item.score },
                { value: totalDuration },
                { value: item.status || "" }
            ];

            if (item.questions && item.questions.length > 0) {
                item.questions.forEach(q => {
                    row.push({ value: q.thisscore });
                    row.push({ value: q.duration });
                });

                // Fill empty cells if fewer questions than max
                for (let i = item.questions.length; i < maxQuestions; i++) {
                    row.push({ value: 0 }); // Empty Score
                    row.push({ value: 0 }); // Empty Duration
                }
            } else {
                // No questions, fill with zeros
                for (let i = 0; i < maxQuestions; i++) {
                    row.push({ value: 0 }); // Empty Score
                    row.push({ value: 0 }); // Empty Duration
                }
            }

            row.push({ value: item.timerin });
            row.push({ value: item.timerout });
            row.push({ value: "Higher" });
            row.push({ value: "REACHUM" });

            rows.push(row);
        });

        await writeXlsx(rows, { fileName: `${roomname}-${formattedDate}-REACHUM.xlsx` });
    };

    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadlessonFile} className="savespread">Download</button>
                                </div>
                        </div>
                    </>
    )
}

export default Cdlessonlog;
