import React, { useState, useEffect } from "react";
import Foot from "../components/Foot";
import Navbars from "../components/Navbar";
import {Helmet} from "react-helmet";
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Down from "../img/dash/down.png";
import Moveicon from "../img/howto/moveicon.jpg";
import Info from "../img/howto/i.jpg";
import Movecicon from "../img/host/newicon/move.png";
import Copylink from "../img/host/newicon/copy.png";
import Editthis from "../img/host/newicon/edit.png";
import Deletethis from "../img/host/newicon/delete.png";
import People from "../img/overview/people.png";
import Sendthis from "../img/overview/submit.png";
import Correctimg from "../img/view/check-mark.png";
import Wrongimg from "../img/view/close.png";
import Zoomin from "../img/zoom-in.png";
import Zoomout from "../img/zoom-out.png";
import Recoder from "../img/userguide/recoder.png";
import Cmimg from "../img/userguide/cm.png";
import Dash from "../img/userguide/Dash.png";
import Zoom from "../img/userguide/zoom.png";
import Google from "../img/userguide/google.png";
import Teams from "../img/userguide/teams.png";
import Reports from "../img/userguide/report.png"
import "./css/support.css";
import Duplicate from "../img/userguide/duplcate.png";
import Exp from "../img/userguide/exp.png"

const Userguide = () => {
    const [key, setKey] = useState('home');
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    function gotothisfastpage(){
        openInNewTab("https://fast.com")
       }
    function gotothisspeedpage(){
        openInNewTab("https://www.speedtest.net")
    }
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
        });
    };


        return (
            <>
            <Helmet>
                <title>User Guide</title>
            </Helmet>
            <Navbars/>
                <div className="supportpage">
                    <div className="reachumuserguide-container">
                        <div className="reachumuserguide-header">
                            <h1>REACHUM User Guide</h1>
                            <p>Your comprehensive resource for mastering the REACHUM platform</p>
                            <div className="reachumuserguide-headerversion">
                                <p>Version 2.3 | Last Updated: April 2, 2025<br/>
                                    This guide is regularly updated as new features are released. Check back for the
                                    latest information.
                                </p>
                            </div>
                        </div>
                        <br/>
                        <section className="reachumuserguide-section">
                            <h2>Table of Contents</h2>
                            <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                <div>
                                    <h3 className="reachumuserguide-green">1. Getting Started</h3>
                                    <ul>
                                        <li>
                                            <p onClick={() => scrollToSection('introduction')}
                                               className="reachumuserguide-underline">
                                                Introduction
                                            </p>
                                        </li>
                                        <li>
                                            <p onClick={() => scrollToSection('DashboardOverview')}
                                               className="reachumuserguide-underline">Dashboard Overview
                                            </p>
                                        </li>
                                        <li>
                                            <p onClick={() => scrollToSection('ManagingLessons')}
                                               className="reachumuserguide-underline">Creating and Managing Lessons
                                            </p>
                                        </li>
                                        <br/>
                                    </ul>
                                    <h3 className="reachumuserguide-green">2. Content Creation</h3>
                                    <ul>
                                        <li><p onClick={() => scrollToSection('ContentTypes')}
                                               className="reachumuserguide-underline">Content Types Overview
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('DiscussionTools')}
                                               className="reachumuserguide-underline">Discussion Tools
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('SafeAskFeature')}
                                               className="reachumuserguide-underline">SafeAsk Feature
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('LeaderboardGames')}
                                               className="reachumuserguide-underline">Leaderboard Games
                                        </p>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <h3 className="reachumuserguide-green">3. Delivering Lessons</h3>
                                    <ul>
                                        <li><p onClick={() => scrollToSection('SharingLessons')}
                                               className="reachumuserguide-underline">Sharing Lessons
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('RemoteLessons')}
                                               className="reachumuserguide-underline">Remote Lessons
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('In-Person')}
                                               className="reachumuserguide-underline">In-Person Lessons
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('Self-Study')}
                                               className="reachumuserguide-underline">Self-Study Lessons
                                        </p>
                                        </li>
                                    </ul>

                                    <h3>4. Analytics & Advanced Features</h3>
                                    <ul>
                                        <li><p onClick={() => scrollToSection('Reports')}
                                               className="reachumuserguide-underline">Reports and Analytics
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('VideoConferencing')}
                                               className="reachumuserguide-underline">Video Conferencing
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('MiroWhiteboard')}
                                               className="reachumuserguide-underline">Miro Whiteboard
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('AdditionalFeatures')}
                                               className="reachumuserguide-underline">Additional Features
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('Frequently')}
                                               className="reachumuserguide-underline">Frequently Asked Questions
                                        </p>
                                        </li>
                                        <li><p onClick={() => scrollToSection('GettingSupport')}
                                               className="reachumuserguide-underline">Getting Support
                                        </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <br/>
                        <section className="reachumuserguide-section" id="introduction">
                            <h2>1. Getting Started</h2>
                            <h3>Introduction</h3>
                            <p>
                                Welcome to REACHUM, your comprehensive digital hub for content sharing and interactive
                                learning!
                                This platform allows you to upload and organize various types of content—from files to
                                website
                                URLs—in one convenient location.
                            </p>
                            <p>
                                REACHUM is designed for easy organization and asynchronous sharing of educational
                                content,
                                helping educators create engaging lessons and track student progress effectively.
                            </p>

                        </section>

                        <section className="reachumuserguide-section" id="DashboardOverview">
                            <h3>Dashboard Overview</h3>
                            <h4>Lesson Menu and Lesson List</h4>
                            <ul>
                                <li><strong>Lesson Menu:</strong> Where you create and name lessons and folders</li>
                                <li><strong>Lesson List:</strong> Displays all lessons in chronological order by
                                    creation date
                                </li>
                            </ul>
                            <div className="reachumuserguide-card reachumuserguide-card-yellow">
                                <h4>Important Reminder: Save Your Changes</h4>
                                <p>Whenever you make changes to your folder structure, lesson organization, or folder
                                    names, be sure to click the <strong>"Save
                                        Changes"</strong> button before leaving the page. Any unsaved changes will be
                                    lost if you navigate away without saving.
                                </p>
                            </div>
                            <br/>
                            <img src={Dash} className="reachumuserguide-img" />
                            <br/>
                            <br/>
                            <h3>Creating Folders for Organization</h3>
                            <ol>
                                <li>Select <strong>Add Folder</strong> in the Lesson Menu</li>
                                <li>Enter a folder name</li>
                                <li>Create subfolders by clicking the plus sign next to the double folder icon at the
                                    bottom
                                </li>
                            </ol>

                            <h3>Key Features</h3>
                            <p>These features are displayed in green at the bottom of the menu:</p>
                            <ul>
                                <li>Content Manager</li>
                                <li>Share Lesson</li>
                                <li>Reports</li>
                                <li>Lesson Links</li>
                            </ul>

                            <h3>Rearranging Lessons</h3>
                            <p>Use the <img src={Moveicon}/> Move icon to drag lessons to your preferred order in the
                                Lesson List</p>

                            <div className="reachumuserguide-tip">
                                <p><strong>Tip:</strong> Create a clear folder structure from the start to keep your
                                    content well-organized as you add more lessons. Remember to click "Save Changes"
                                    after making any modifications in the dashboard to ensure your work is preserved.
                                </p>
                            </div>
                        </section>

                        <section className="reachumuserguide-section" id="ManagingLessons">
                            <h3>Creating and Managing Lessons</h3>
                            <h4>Creating a New Lesson</h4>
                            <ol>
                                <li>Select <strong>Add Lesson</strong> on the Lesson Menu</li>
                                <li>Name your lesson</li>
                                <li>Select <strong>Next</strong></li>
                                <li>Select <strong>Create Lesson</strong></li>
                            </ol>
<br/>
                            <img src={Cmimg} className="reachumuserguide-img" />
                            <br/>
                            <br/>
                            <h4>Duplicating Existing Lessons</h4>
                            <p>You can copy content from any existing lesson into a new one. While you can't edit
                                duplicated content directly, you can:</p>
                            <ul>
                                <li>Delete any slide</li>
                                <li>Add new content slides</li>
                                <li>Rearrange the order of slides</li>
                            </ul>
<br/>
                            <h5>Adding Content to Your Lesson</h5>
                            <ol>
                                <li>Select the lesson name</li>
                                <li>Select <strong>Manage Content</strong> on the Lesson Menu</li>
                                <li>In the Content Manager, select <strong>Add Content</strong></li>
                                <li>Choose from 30 different content types (click the <img src={Info} /> information icon for
                                    descriptions)
                                </li>
                                <li>Complete your content and select <strong>Submit</strong></li>
                            </ol>
                            <p>Each piece of content appears as an individual slide in the Content Manager</p>
<br/>
                            <h4>Organizing Lesson Content</h4>
                            <ul>
                                <li><img src={Movecicon}/> <strong>Rearrange slides:</strong> Drag slides using the move
                                    icon at the bottom of each slide
                                </li>
                                <li><img src={Copylink}/> <strong>Copy link:</strong> Use the copy icon to duplicate a
                                    slide for use elsewhere in the lesson
                                </li>
                                <li><img src={Editthis}/> <strong>Edit slides:</strong> Click the edit icon to modify
                                    existing content
                                </li>
                                <li><img src={Deletethis}/> <strong>Delete slides:</strong> Click the delete icon at the
                                    top right of any slide
                                </li>
                                <li><strong>Rename Slides</strong>: Click on the centered heading text at the top of the slide.</li>
                                <li><strong>Preview slides:</strong> Simply click on any slide (exit preview by clicking
                                    the X)
                                </li>
                                <li><img src={Zoomout}/>/<img src={Zoomin}/> <strong>Maximize/Minimize
                                    view:</strong> Use the button in the bottom right corner
                                    to toggle between expanded and compact slide views
                                </li>
                            </ul>
                            <div className="reachumuserguide-card reachumuserguide-card-yellow">
                                <h5>Remember to Save Your Changes</h5>
                                <p>
                                    After editing any content or making changes to slides, always click the <strong>"Save
                                    Changes"</strong> button
                                    before exiting the edit page.
                                </p>
                                <p>
                                    <strong>Important:</strong> Unsaved changes will be lost if you navigate away from
                                    the page without saving.
                                </p>
                            </div>
                        </section>
<br/>
                        <section className="reachumuserguide-section" id="ContentTypes">
                            <h2>2. Content Creation</h2>
                            <h3>Content Types Overview</h3>
                            <p>REACHUM offers 30 different content types organized into three categories:</p>
                            <div className="reachumuserguide-safeask-feature">
                                <h4>Featured Tool: SafeAsk</h4>
                                <p>
                                    The SafeAsk Discussion Tool is one of REACHUM's most powerful features for creating
                                    an inclusive learning environment.
                                    It provides participants with a way to anonymously ask or answer questions, offer
                                    comments, or post pictures
                                    without fear of judgment.
                                </p>
                                <p>
                                    SafeAsk is particularly effective for sensitive topics, complex subject matter, or
                                    classrooms
                                    with participation imbalances. More details about SafeAsk are available in the
                                    Discussion Tools section below.
                                </p>
                            </div>
                            <br/>
                            <h4>Media Tools</h4>
                            <p>
                                REACHUM offers a wide variety of media tools to enhance your lessons with engaging
                                visual and interactive content:
                            </p>
                            <div className="reachumuserguide-card reachumuserguide-card-green">
                                <h4>Giphy Library Integration</h4>
                                <p>
                                    REACHUM provides seamless access to the world's largest library of animated GIFs to
                                    make your lessons more engaging and visually appealing.
                                </p>

                                <h5>Accessing Giphy Library</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li>Select "Add Content" in the Content Manager</li>
                                    <li>Choose "Giphy" from the Media category</li>
                                    <li>Search for GIFs by keyword, category, or trending content</li>
                                    <li>Preview GIFs before adding them to your lesson</li>
                                </ul>

                                <h5>Educational Applications</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Concept Visualization:</strong> Use GIFs to demonstrate processes,
                                        movements, or sequences
                                    </li>
                                    <li><strong>Engagement Boosters:</strong> Add humor and visual interest to maintain
                                        attention
                                    </li>
                                    <li><strong>Emotional Context:</strong> Convey emotions or reactions relevant to
                                        learning content
                                    </li>
                                    <li><strong>Transition Elements:</strong> Use GIFs as visual breaks between topics
                                        or activities
                                    </li>
                                </ul>

                                <h5>Content Filtering</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Safe Search:</strong> Default content filtering ensures
                                        classroom-appropriate content
                                    </li>
                                    <li><strong>Education Category:</strong> Access education-specific GIFs for academic
                                        contexts
                                    </li>
                                </ul>
                            </div>
                            <div className="reachumuserguide-card reachumuserguide-card-purple">
                                <h4>Adobe Express Integration</h4>
                                <p>
                                    REACHUM integrates with Adobe Express, a powerful design
                                    tool similar to Canva that enables educators to create professional-quality
                                    graphics, presentations, and visual content without design expertise.
                                </p>

                                <h5>Similarities to Canva</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Drag-and-Drop Interface:</strong> Intuitive design tools with no
                                        learning curve
                                    </li>
                                    <li><strong>Template Library:</strong> Pre-designed templates for various
                                        educational contexts
                                    </li>
                                    <li><strong>Content Assets:</strong> Access to photos, icons, illustrations, and
                                        fonts
                                    </li>
                                    <li><strong>Responsive Design:</strong> Content looks good on any device or
                                        screen size
                                    </li>
                                </ul>

                                <h5>Accessing Adobe Express</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li>Select "Add Content" in the Content Manager</li>
                                    <li>Choose "Adobe Express" from the Media category</li>
                                    <li>Log in with Adobe credentials or create a free account</li>
                                    <li>Design content directly within the REACHUM interface</li>
                                </ul>

                                <h5>Available Templates</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Educational Infographics:</strong> Visual learning aids for complex
                                        concepts
                                    </li>
                                    <li><strong>Social Media Graphics:</strong> Announcements and updates for
                                        classes
                                    </li>
                                    <li><strong>Presentations:</strong> Engaging slide decks with modern design</li>
                                    <li><strong>Web Pages:</strong> Simple landing pages for projects or resources
                                    </li>
                                    <li><strong>Posters & Flyers:</strong> Event announcements and classroom
                                        decorations
                                    </li>
                                    <li><strong>Worksheets & Handouts:</strong> Visually appealing activity sheets
                                    </li>
                                </ul>

                                <h5>Educational Benefits</h5>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Visual Learning:</strong> Create engaging visual explanations of
                                        complex topics
                                    </li>
                                    <li><strong>Professional Results:</strong> Produce high-quality visuals without
                                        design skills
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h4>Additional Media Tools</h4>
                                <p>
                                    REACHUM provides a complete suite of media tools for creating engaging lesson
                                    content:
                                </p>
                                <ul>
                                    <li><strong>PowerPoint/PDF Upload:</strong> Import existing presentations and
                                        documents
                                    </li>
                                    <li><strong>Image:</strong> Add photos and graphics
                                    </li>
                                    <li><strong>Drawing Tool:</strong> Create freehand illustrations and annotations
                                    </li>
                                    <li><strong>Flashcards:</strong> Design interactive study cards with text or images
                                    </li>
                                    <li><strong>Video Integration:</strong> Embed YouTube, Vimeo, or upload your own
                                        videos
                                    </li>
                                    <li><strong>Screen Recording:</strong> Capture your screen with audio for
                                        demonstrations
                                    </li>
                                    <li><strong>Audio Files:</strong> Add sound clips, music, or narration</li>
                                    <li><strong>Web Page Embedding:</strong> Incorporate external web resources</li>
                                    <li><strong>Text Slide:</strong> Create simple PowerPoint-like slides with formatted
                                        text and layouts
                                    </li>
                                </ul>
                            </div>
                            <br/>
                            <h4 id="DiscussionTools">Discussion Tools</h4>
                            <p>REACHUM offers four anonymous discussion tools that promote engagement:</p>

                            <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                <div className="reachumuserguide-card reachumuserguide-card-blue">
                                    <h5>Poll</h5>
                                    <p>Anonymously measures participant attitudes and opinions</p>
                                    <h5>Poll Timer Features</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li><strong>Customizable Duration:</strong> Set poll time limits from 30
                                            seconds
                                            to 180 seconds
                                        </li>
                                        <li><strong>Visual Countdown:</strong> Participants see a timer showing
                                            remaining time
                                        </li>
                                        <li><strong>Auto-close Option:</strong> Polls can automatically close when
                                            the
                                            timer expires
                                        </li>
                                    </ul>
                                    <h5>Use Cases</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li>Reset polls for reuse with new groups</li>
                                        <li>Start fresh after practice sessions</li>
                                    </ul>

                                    <h5>Implementation Details</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li>A clear "Delete Results" button in the Edit panel</li>
                                    </ul>
                                </div>

                                <div className="reachumuserguide-card reachumuserguide-card-green">
                                    <h5>Ranking Poll</h5>
                                    <p>Surveys participants and allows them to order items by preference</p>
                                    <h5>Ranking Poll Timer Features</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li><strong>Customizable Duration:</strong> Set Ranking poll time limits
                                            from 30
                                            seconds
                                            to 180 seconds
                                        </li>
                                        <li><strong>Visual Countdown:</strong> Participants see a timer showing
                                            remaining time
                                        </li>
                                        <li><strong>Auto-close Option:</strong> Ranking Polls can automatically
                                            close
                                            when the
                                            timer expires
                                        </li>
                                    </ul>
                                    <h5>Use Cases</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li>Reset Ranking polls for reuse with new groups</li>
                                        <li>Start fresh after practice sessions</li>
                                    </ul>

                                    <h5>Implementation Details</h5>
                                    <ul className="reachumuserguide-benefit-list">
                                        <li>A clear "Delete Results" button in the Edit panel</li>
                                    </ul>
                                </div>

                                <div className="reachumuserguide-card reachumuserguide-card-purple">
                                    <h5>Word Cloud</h5>
                                    <p>Great icebreaker activity that visually represents participant responses in a
                                        dynamic, growing cloud formation.</p>

                                    <div className="reachumuserguide-benefit-section">
                                        <h6 className="reachumuserguide-benefit-title">Key Features:</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Response Limit:</strong> Each response is limited to 35
                                                characters to keep the cloud readable
                                            </li>
                                            <li><strong>Multiple Inputs:</strong> Each participant can contribute up
                                                to
                                                three responses
                                            </li>
                                            <li><strong>Visual Impact:</strong> Every used word appears in the
                                                display
                                            </li>
                                            <li><strong>Real-time Updates:</strong> The cloud builds dynamically as
                                                participants submit answers
                                            </li>
                                        </ul>

                                        <h6 className="reachumuserguide-benefit-title">Instructor Controls:</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li>Save all responses to a spreadsheet for later analysis</li>
                                            <li>Clear responses to reset the activity</li>
                                            <li>Customize the prompt to guide participant responses</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="reachumuserguide-card reachumuserguide-card-yellow">
                                    <h5>SafeAsk</h5>
                                    <p>Provides anonymous way to ask/answer questions, offer comments, or post
                                        pictures.</p>

                                    <div className="reachumuserguide-benefit-section">
                                        <h6 className="reachumuserguide-benefit-title">Key Benefits:</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Enhanced Student Engagement:</strong> Reduces participation
                                                anxiety for shy students and creates a level playing field
                                            </li>
                                            <li><strong>Improved Learning Outcomes:</strong> Students feel free to
                                                ask
                                                "basic" questions they might otherwise suppress
                                            </li>
                                            <li><strong>Emotional Safety:</strong> Creates a secure environment for
                                                intellectual risk-taking
                                            </li>
                                            <li><strong>Teaching Insights:</strong> Provides instructors with
                                                unfiltered
                                                feedback about comprehension gaps
                                            </li>
                                        </ul>

                                        <h6 className="reachumuserguide-benefit-title">Instructor Controls:</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li>View participant names/emails through the dropdown menu</li>
                                            <li>Save responses as Excel spreadsheet</li>
                                            <li>Remove individual responses as needed</li>
                                            <li>Download all responses for later analysis</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <section className="reachumuserguide-section" id="SafeAskFeature">
                                <h3>SafeAsk: In-Depth</h3>
                                <p>
                                    The SafeAsk Discussion Tool is a cornerstone of REACHUM's approach to creating
                                    inclusive,
                                    engaged learning environments. Here's a comprehensive look at this powerful feature:
                                </p>

                                <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                    <div className="reachumuserguide-card reachumuserguide-card-blue">
                                        <h4>Enhancing Student Engagement</h4>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Reduces participation anxiety:</strong> Students who feel shy or
                                                anxious about speaking in front of peers can still contribute
                                                meaningfully
                                            </li>
                                            <li><strong>Encourages universal participation:</strong> Gives voice to
                                                students
                                                who might otherwise remain silent in traditional discussions
                                            </li>
                                            <li><strong>Creates a level playing field:</strong> Removes biases related
                                                to
                                                popularity, perceived intelligence, or social status
                                            </li>
                                            <li><strong>Increases question frequency:</strong> Students ask more
                                                questions
                                                when they don't fear judgment
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="reachumuserguide-card reachumuserguide-card-green">
                                        <h4>Improving Learning Outcomes</h4>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Promotes authentic inquiry:</strong> Students feel free to ask
                                                "basic" questions they might otherwise suppress
                                            </li>
                                            <li><strong>Facilitates honest feedback:</strong> Provides instructors with
                                                unfiltered insights about comprehension gaps
                                            </li>
                                            <li><strong>Encourages critical thinking:</strong> Students can challenge
                                                ideas
                                                without fear of social repercussions
                                            </li>
                                            <li><strong>Supports diverse perspectives:</strong> Allows students to
                                                express
                                                minority viewpoints safely
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                    <div className="reachumuserguide-card reachumuserguide-card-purple">
                                        <h4>Supporting Emotional & Social Development</h4>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Builds confidence:</strong> Students gain confidence as they see
                                                their anonymous contributions valued
                                            </li>
                                            <li><strong>Reduces peer pressure:</strong> Minimizes conformity effects in
                                                discussion and debate
                                            </li>
                                            <li><strong>Creates psychological safety:</strong> Establishes a secure
                                                environment for intellectual risk-taking
                                            </li>
                                            <li><strong>Encourages self-reflection:</strong> Students can process
                                                difficult
                                                topics without social influence
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="reachumuserguide-card reachumuserguide-card-yellow">
                                        <h4>Teaching Benefits</h4>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Reveals true understanding:</strong> Instructors gain better
                                                insight
                                                into actual student comprehension
                                            </li>
                                            <li><strong>Identifies misconceptions:</strong> Anonymous questions often
                                                reveal
                                                common misunderstandings
                                            </li>
                                            <li><strong>Allows sensitive topics exploration:</strong> Facilitates
                                                discussion
                                                of controversial or sensitive subjects
                                            </li>
                                            <li><strong>Provides immediate feedback:</strong> Instructors can gauge
                                                engagement and understanding in real-time
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="reachumuserguide-card reachumuserguide-card-gray">
                                    <h4>Using SafeAsk Effectively</h4>
                                    <ol>
                                        <li>Add a SafeAsk slide to your lesson through the Content Manager's Add Content
                                            menu
                                        </li>
                                        <li>Customize the prompt or question to guide student responses</li>
                                        <li>During the lesson, encourage all students to participate</li>
                                        <li>Allow sufficient time for thoughtful responses</li>
                                        <li>Use the dropdown menu to view participant names/emails when necessary</li>
                                        <li>Save valuable responses as an Excel spreadsheet for future reference</li>
                                        <li>Remove any inappropriate responses using the Delete Response feature</li>
                                        <li>Incorporate anonymous responses into your teaching by addressing questions,
                                            highlighting insights, or using comments as discussion starters
                                        </li>
                                    </ol>
                                </div>

                                <div className="reachumuserguide-safeask-feature">
                                    <h5>Real-World Preparation</h5>
                                    <p>
                                        SafeAsk not only improves classroom dynamics but also prepares students for
                                        professional environments:
                                    </p>
                                    <ul>
                                        <li>Many workplaces use anonymous feedback and idea submission systems</li>
                                        <li>Students develop digital citizenship skills through responsible use of
                                            anonymous
                                            platforms
                                        </li>
                                        <li>Practice constructing clear, effective questions and responses helps in
                                            professional communication
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            <br/>
                            <h4 id="LeaderboardGames">Leaderboard Games</h4>
                            <ul>
                                <li>9 competitive games with time-based or untimed scoring</li>
                                <li>Each game generates an answer slide and leaderboard slide</li>
                                <li>Correct answers are visible at the bottom of participants' devices</li>
                            </ul>
                            <div className="reachumuserguide-card reachumuserguide-card-gray">
                                <h4>Viewing Reports on a Leaderboard Game's Slide</h4>
                                <p>
                                    Access participant responses instantly without leaving your lesson. In addition
                                    to
                                    the comprehensive reports found in Analytics, REACHUM displays student data
                                    directly
                                    within each slide—both during live sessions and when reviewing past lessons.
                                </p>
                                <p>
                                    Select the <img src={People} width="23"/> to access participant responses within
                                    a
                                    slide. This will bring you to a screen with the following icons:
                                </p>
                                <ul>
                                    <li><img src={People} width="23"/> signifies the number of participants logged
                                        into
                                        your lesson.
                                    </li>
                                    <li><img src={Sendthis} width="23"/> signifies the number of students who
                                        answered
                                        the question.
                                    </li>
                                    <li><img src={Correctimg} width="21"/> signifies how many participants answered
                                        the
                                        question
                                        correctly.
                                    </li>
                                    <li><img src={Wrongimg} width="18"/> signifies how many participants answered
                                        the
                                        question
                                        incorrectly.
                                    </li>
                                </ul>
                            </div>
                        </section>

                        <br/>
                        <section className="reachumuserguide-section" id="SharingLessons">
                            <h2>3. Delivering Lessons</h2>
                            <h3>Sharing Your Lessons</h3>
<br/>
                            <h4>Sharing with Colleagues (Two Methods)</h4>
                            <div className="reachumuserguide-card reachumuserguide-card-blue">
                                <h5>Method 1: Share for Delivery</h5>
                                <ol>
                                    <li>Select <strong>Share Lesson</strong> on the Lesson Menu</li>
                                    <li>Enter the recipient's email address</li>
                                    <li>The lesson will appear in their Lesson List</li>
                                </ol>
                                <p>Recipients can:</p>
                                <ul>
                                    <li>Deliver the lesson just like the original creator</li>
                                    <li>Add new content slides</li>
                                    <li>Rearrange or delete slides</li>
                                    <li>Cannot edit existing content slides</li>
                                </ul>
                            </div>

                            <div className="reachumuserguide-card reachumuserguide-card-green">
                                <h5>Method 2: Export for Viewing</h5>
                                <ol>
                                    <li>In the Content Manager, select the <strong>Export</strong> button</li>
                                    <li>Recipients can view slides in a print preview format</li>
                                </ol>
                                <p>This method is for reference only—recipients cannot deliver the lesson</p>
                            </div>
                        </section>


                        <section className="reachumuserguide-section">
                            <h3>Starting a Lesson</h3>
                            <p>
                                Access the Lesson Links button in the Lesson Menu by selecting your lesson name.
                                You'll see three tabs:
                            </p>

                            <h4 id="RemoteLessons">Remote Lessons</h4>
                            <ul>
                                <li><strong>Start Lesson:</strong> Begins the remote session</li>
                                <li><strong>Calendar Invite:</strong> Send invitations via Google, iCal, Outlook,
                                    Microsoft 365, Teams, or Yahoo
                                </li>
                                <li><strong>Direct Links:</strong> Copy links for students, group leaders, or co-hosts
                                </li>
                                <li><strong>Host Control Guide:</strong> Explains navigation icons shown during
                                    presentation
                                </li>
                            </ul>
                            <div className="reachumuserguide-card reachumuserguide-card-blue" id="VideoConferencing">
                                <h5>Integrated Video Conferencing</h5>
                                <p>REACHUM includes robust built-in video conferencing capabilities to enhance your
                                    remote teaching experience:</p>

                                <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                    <div>
                                        <h6 className="reachumuserguide-benefit-title">Communication Tools</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Face-to-Face Video:</strong> HD video for clear participant
                                                interaction
                                            </li>
                                            <li><strong>Audio Controls:</strong> Mute/unmute individual or all
                                                participants
                                            </li>
                                            <li><strong>Raise Hand:</strong> Digital hand-raising feature for orderly
                                                participation without interrupting
                                            </li>
                                            <li><strong>Chat:</strong> Public messaging</li>
                                            <li><strong>Reactions:</strong> Quick emoji responses for non-verbal
                                                feedback
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h6 className="reachumuserguide-benefit-title">Presentation Tools</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Multi-Screen Sharing:</strong> Allow multiple participants to
                                                share screens simultaneously for comparison or collaborative
                                                presentations
                                            </li>
                                            <li><strong>Screen Sharing:</strong> Show applications or your entire
                                                desktop
                                            </li>
                                            <li><strong>YouTube:</strong> Watch your uploaded videos on YouTube together!</li>
                                            <li><strong>Recording:</strong> Save sessions for absent students or review
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="reachumuserguide-grid reachumuserguide-grid-2">
                                    <div>
                                        <h6 className="reachumuserguide-benefit-title">Collaborative Features</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Breakout Groups:</strong> Create smaller discussion rooms for
                                                team activities
                                            </li>
                                            <li><strong>Miro Whiteboard:</strong> Collaborate using a whiteboard to express and share your ideas.
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h6 className="reachumuserguide-benefit-title">Management Features</h6>
                                        <ul className="reachumuserguide-benefit-list">
                                            <li><strong>Session Controls:</strong> Lock meetings or remove participants
                                            </li>
                                            <li><strong>Waiting Room:</strong> Admit participants when you're ready</li>
                                        </ul>
                                    </div>
                                </div>

                                <p>
                                    <strong>Flexible Interface Layout:</strong> The remote lesson interface offers
                                    adaptable viewing options:
                                </p>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Split View:</strong> Video conference area alongside the asynchronous
                                        display area for lesson content and interactive elements
                                    </li>
                                    <li><strong>Full-Screen Video:</strong> Expand the video conference to full screen
                                        when focusing solely on discussion or collaboration
                                    </li>
                                    <li><strong>Content Focus:</strong> Minimize the video area to maximize content
                                        display when needed
                                    </li>
                                </ul>
                                <p>This flexible layout allows instructors to tailor the
                                    interface based on current activities, creating a dynamic environment that can shift
                                    between video-focused discussion, content presentation, or balanced interaction.</p>
                            </div>
                            <div className="reachumuserguide-card reachumuserguide-card-purple" id="MiroWhiteboard">
                                <h5>Miro Whiteboard Integration</h5>
                                <p>
                                    REACHUM integrates with Miro's powerful collaborative whiteboard platform, providing
                                    an enhanced visual workspace for remote collaboration:
                                </p>
                                <ul className="reachumuserguide-benefit-list">
                                    <li><strong>Real-time Collaboration:</strong> Multiple participants can work
                                        simultaneously on the same whiteboard
                                    </li>
                                    <li><strong>Rich Toolkit:</strong> Access sticky notes, shapes, connectors, drawing
                                        tools, and text formatting
                                    </li>
                                    <li><strong>Ready-made Templates:</strong> Choose from various templates for
                                        brainstorming, planning, mapping, and more
                                    </li>
                                    <li><strong>Multimedia Support:</strong> Embed images, videos, and documents
                                        directly in the whiteboard
                                    </li>
                                    <li><strong>Persistent Workspace:</strong> Save whiteboards for future sessions to
                                        build on previous work
                                    </li>
                                    <li><strong>Export Options:</strong> Download whiteboard content as images or PDFs
                                        for sharing or evaluation
                                    </li>
                                </ul>
                                <p>
                                    The Miro whiteboard is accessible directly within the video conferencing interface,
                                    allowing for seamless transitions between discussion and visual collaboration.
                                </p>
                            </div>
                            <div className="reachumuserguide-tip">
                                <p><strong>Important Note:</strong> Video conferencing sessions expire 1 year after
                                    creation.</p>
                            </div>
<br/>
                            <h4 id="In-Person">In-Person Lessons</h4>
                            <ul>
                                <li>Includes start link and calendar invitation options</li>
                                <li><strong>Student Link:</strong> Can be copied and emailed</li>
                                <li><strong>QR Code:</strong> Can be displayed or downloaded as PDF</li>
                                <li>Works with video conferencing platforms such as <img src={Zoom} /> <img src={Teams} /> <img src={Google} /> and more — simply share your screen and distribute the participant link.
                                </li>
                            </ul>
<br/>
                            <h4 id="Self-Study">Self-Study Lessons</h4>
                            <ul>
                                <li>Allows participants to work at their own pace</li>
                                <li>Helpful for absent students or those wanting to review material</li>
                                <li>Options to send calendar invites, copy student links, or generate QR codes</li>
                                <li>Includes a Student Control Guide for navigation assistance</li>
                            </ul>
                        </section>

<br/>
                        <section className="reachumuserguide-section" id="Reports">
                            <h2>4. Analytics & Advanced Features</h2>
                            <h3>Reports and Analytics</h3>
                            <p>
                            Access reports by clicking on a lesson and selecting the
                                green <strong>Reports</strong> button.
                            </p>

                            <h4>Available Report Types</h4>
                            <ul>
                                <li><strong>Lesson Log:</strong> Detailed participant information including scores, time
                                    logged, and learning mode
                                </li>
                                <li><strong>Activities View:</strong> Shows scores for each question and time spent on
                                    individual questions
                                </li>
                                <li><strong>Leaderboard:</strong> Displays participant total scores in descending order
                                </li>
                                <li><strong>Report by Date:</strong> Search for reports across a specific time range
                                </li>
                                <li><strong>SafeAsk Reports:</strong> Available when SafeAsk activities were part of the
                                    lesson
                                </li>
                            </ul>

                            <h4>Sharing Reports</h4>
                            <ol>
                                <li>Select <strong>Share Reports</strong> in the Report by Date screen</li>
                                <li>Enter up to two email addresses</li>
                                <li>Select <strong>Copy Share Link</strong> and send to recipients</li>
                                <li>Recipients will have access to past and future reports until sharing is stopped</li>
                            </ol>
                           <img src={Reports} className="reachumuserguide-img" />
                            <br/>
                            <br/>
                            <div className="reachumuserguide-card reachumuserguide-card-gray">
                                <h5>Understanding Report Symbols</h5>
                                <ul>
                                    <li>✓ Answered correctly</li>
                                    <li>? Did not answer</li>
                                    <li>✗ Answered incorrectly</li>
                                </ul>
                            </div>
                        </section>


                        <section className="reachumuserguide-section" id="AdditionalFeatures">
                            <h3>Additional Features</h3>
                            <h4>Rehearse Lesson</h4>
                            <ul>
                                <li>Select <strong>Rehearse</strong> in the Content Manager to practice before teaching
                                </li>
                                <li>Only visible to you (private mode)</li>
                            </ul>

                            <h4>Viewing Live Responses</h4>
                            <p>
                                REACHUM provides instructors with real-time insights into participant engagement and
                                performance through live response tracking available during or after lesson delivery.
                            </p>
                            <div className="reachumuserguide-card reachumuserguide-card-blue">
                                <h5>Response Tracking Icons</h5>
                                <p>
                                    When viewing response data for any interactive activity, you'll see these helpful
                                    indicators:
                                </p>
                                <ul>
                                    <li><strong><img src={People} width="23"/> Participants:</strong> Shows the total
                                        number of participants
                                        currently logged into your lesson
                                    </li>
                                    <li><strong><img src={Sendthis} width="22"/> Responses:</strong> Indicates how many
                                        students have submitted an
                                        answer to the question
                                    </li>
                                    <li><strong><img src={Correctimg} width="20"/> Correct:</strong> Displays the number
                                        of participants who answered
                                        correctly
                                    </li>
                                    <li><strong><img src={Wrongimg} width="18"/> Incorrect:</strong> Shows how many
                                        participants answered incorrectly
                                    </li>
                                </ul>
                                <p>
                                    This at-a-glance data helps you gauge engagement, understand comprehension levels,
                                    and make real-time decisions about whether to reteach concepts or move forward with
                                    the
                                    lesson.
                                </p>
                            </div>
                        </section>
                        <br/>
                        <div className="reachumuserguide-section-accorder" id="Frequently">
                        <h2>Frequently Asked Questions </h2>
                            <br/>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>1. Is REACHUM cloud-based or do I need to install software?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>REACHUM is entirely cloud-based - no software installation required.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>2. A participant’s device is stuck on a previous slide and will not move forward
                                        with the lesson.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>The participant should refresh their screen by swiping down.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>3. After I uploaded the webpage URL in the 'Add Content' section, why am I seeing
                                        an error on the slide?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Some webpages can't be embedded due to security settings set by the site itself
                                            (like preventing embedding in other platforms). If you're seeing an error after
                                            uploading the URL in the "Add Content" section, try pasting the link into the
                                            "Enter the URL here" field below instead. This alternative method sometimes
                                            works better with sites that have embedding restrictions.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>4. Is there an option to add more than one multiple choice question to the same
                                        slide?</Accordion.Header>
                                    <Accordion.Body>
                                      <p>No. Currently, each multiple-choice question is placed on a separate slide to
                                          maintain clarity and engagement on the participant’s individual device. </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>5. Regarding untimed scoring—I'm aware that scores are typically based on a timer, but how is the score calculated when there's no timer involved?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>The starting score is 40, and it is then calculated based on the percentage of correct answers provided by the participants.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>6. Is there a way to delete all answers to all multiple choice questions in the
                                        same lesson?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes. This can be done by selecting Delete Results in the Report Overview of the
                                            particular lesson. If you would like to delete the answers of specific
                                            participants, this can be done in the Lesson Log of the Report Menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>7. Can polls be set to allow multiple selections or just single choice?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Single choice only.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>8. Where did the results go on the Ranking Poll slide?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Ranking Poll results are only visible in real-time. Once you proceed to the next
                                            slide, the results are no longer available.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>9. If a lesson creator shares a report with a colleague, is the colleague able to
                                        see all reports?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes. The recipient of the shared reports will have access to all reports until
                                            that person’s name is removed from Share Reports on the Report Menu.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>10. If the same lesson is used for two different sessions, do the second session’s
                                        answers override the first session’s answers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>No. The second session’s answers will be added to the first session’s
                                            answers.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>11. How will I see the answers of a self-study?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>They will be added to the lesson report for that particular lesson.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>12. How do I convert a lesson from one delivery mode to another?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Every lesson comes with links for all three delivery modes.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>13. I noticed the duration in the report seems quite long—do you know why that might be?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Because the duration is calculated based on the time between a person's first time in and their last time out.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                    <Accordion.Header>14. Why did I copy a game from one lesson to another, but the score doesn’t appear on the lesson’s leaderboard?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>The game's result belongs to that original lesson.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>15. Are video recordings automatically saved or do I need to manually save
                                        them?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>No,recordings must be saved manually.</p>
                                        <p>It is critical that the Recording is ended by clicking on the Stop Recording
                                            button. In some cases recordings haven't saved correctly, or at all, so to
                                            prevent data loss the recording must be ended before leaving the room.</p>
                                        <p>We recommend saving your Recording immediately after ending your meeting.</p>
                                        <img src={Recoder} width="350"/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>16. Is there a way to check if my video conferencing session is still active or has expired?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>To check the expiration date, go to the content manager for this lesson,
                                            click 'Lesson Links,' and you'll see it there.</p>
                                        <img src={Exp}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>17. What happens to recorded sessions after the 1-year expiration?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>The lesson content remains available after the 1-year expiration, but you'll
                                            no longer be able to access it remotely through REACHUM video conferencing.
                                            To continue using the material for remote sessions, simply create a new
                                            lesson and duplicate the content from the expired one. This allows you to
                                            maintain the same slides while also giving you the opportunity to update the
                                            content as needed.</p>
                                        <img src={Duplicate}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header>18. Can't See Your Score? Here's Why </Accordion.Header>
                                    <Accordion.Body>
                                        <h5>1. You're Using a Private Browser</h5>
                                        <ul>
                                            <li><span className="boldthis"> Switch to a Public Browser Mode</span>: Exit
                                                private/incognito mode and open a regular browser window.
                                            </li>
                                            <li><span className="boldthis"> Clear Browser Cache</span>: In some cases,
                                                clearing
                                                your
                                                browser's cache and cookies can help resolve issues.
                                            </li>
                                        </ul>
                                        <h5>2. Internet Connection May Not Be Stable</h5>
                                        <ul>
                                            <li><span className="boldthis">Check Your Internet Connection</span>: Make
                                                sure
                                                your
                                                Wi-Fi
                                                or mobile data
                                                connection is strong and stable.
                                            </li>
                                            <li><span className="boldthis">Try a Different Network</span>: If possible,
                                                switch
                                                to a
                                                different network or
                                                use a wired connection for better stability.
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                    <Accordion.Header>19. Lagging or freezing video </Accordion.Header>
                                    <Accordion.Body>
                                        <p>When you are having this issue, is your computer heating up? </p>
                                        <ul>
                                            <li>If yes, it sounds like this could be related to your CPU.</li>
                                            <li>If no, the issue you are experiencing might be related to your
                                                network.Sometimes
                                                networks are unpredictable and could cause issues at any minute!
                                            </li>
                                        </ul>
                                        <p>I would suggest checking out our network requirement guide in the below.</p>
                                        <ul>
                                            <li>
                                                <h5>Required Speeds</h5>
                                                <p>At least 3.2 Mbps outbound (upload) and 3.2 Mbps inbound (download)
                                                    are
                                                    required.</p>
                                                <p>Network issues can be diagnosed by using a speed test site like <span
                                                    onClick={gotothisfastpage}
                                                    className="thisfasttext">https://fast.com/</span> or <span
                                                    onClick={gotothisspeedpage}
                                                    className="thisfasttext"> https://www.speedtest.net/</span>.</p>
                                            </li>
                                            <li>
                                                <h5>Network Configuration & Security</h5>
                                                <ol>
                                                    <li>
                                                        <p>Firewall</p>
                                                        <p>If you or your company have restrictive firewall settings,
                                                            you
                                                            may
                                                            experience
                                                            some connection problems. For a smooth experience, we
                                                            recommend:</p>
                                                        <ul>
                                                            <li>
                                                                Opening port 443 for outgoing traffic for both TCP and
                                                                UDP.
                                                            </li>
                                                            <li>Additionally, outgoing traffic is preferably direct and
                                                                not
                                                                sent
                                                                through
                                                                a proxy.
                                                            </li>
                                                        </ul>
                                                        <br/>
                                                    </li>
                                                    <li>
                                                        <p>VPN</p>
                                                        <p>Right now we don't recommend using REACHUM with a VPN.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                    <Accordion.Header>20. I can't see/hear everyone in my meeting or only seeing a blank
                                        white
                                        page </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Since REACHUM is browser based, it is important to make sure that everyone's
                                            set up
                                            is up to
                                            par with our set up. If there are black frames or someone cannot be heard,
                                            it might
                                            be
                                            related to permissions, browser, device or network.</p>
                                        <p>Below are some tips on how to resolve this issue: </p>
                                        <ul>
                                            <li>Refresh your browser</li>
                                            <li>Turn off any software running on your computer that you do not need for
                                                the
                                                meeting.
                                                Close all unnecessary browser windows, tabs, and any video intensive
                                                software.
                                            </li>
                                            <li>Clear the browser cache and restart the browser</li>
                                            <li>Remove and REACHUM background effect</li>
                                            <li>Turn off REACHUM noise reduction</li>
                                            <li>Make you are running the latest version of the browser. There have been
                                                reports
                                                in the
                                                past where similar issues happen when a connection is made through a
                                                Safari or
                                                Edge
                                                browser. For good practice, we recommend using some of standards
                                                compliant
                                                browsers such
                                                as Chrome or Firefox to see if the issue persists.
                                            </li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                    <Accordion.Header>21. Troubleshoot Screen Sharing Issues </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>
                                                <h5>Browser Screen Sharing Permissions</h5>
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="home" title="Chrome">
                                                        <h6>Chrome</h6>
                                                        <p>Chrome doesn't have any specific settings that limit screen
                                                            access.
                                                            If you're
                                                            running into problems or getting an error go to the
                                                            Operating System
                                                            Permissions area for further troubleshooting.</p>
                                                    </Tab>
                                                    <Tab eventKey="profile" title="Firefox">
                                                        <h6>Firefox</h6>
                                                        <p>When you first use screen sharing in Firefox, you'll see a
                                                            pop up
                                                            that asks
                                                            if you'd like to allow REACHUM to access your screen</p>
                                                        <p>If you initially clicked Don't Allow, Firefox won't show this
                                                            prompt
                                                            again
                                                            when you try to share your screen and won't show any sharing
                                                            options. In
                                                            this case, you'll need to manually reset the permissions for
                                                            screen
                                                            access
                                                            by doing the following:</p>
                                                        <ol>
                                                            <li>Click on the "Site information" area, just to the left
                                                                of the
                                                                website
                                                                address
                                                            </li>
                                                            <li>
                                                                Locate the "Share the Screen" item in the list, and
                                                                click the
                                                                "X" next
                                                                to it
                                                            </li>
                                                            <li>Refresh the page, and click on the Share button in the
                                                                bottom
                                                                toolbar to
                                                                re-prompt the screen sharing notification
                                                            </li>
                                                        </ol>
                                                    </Tab>
                                                </Tabs>
                                            </li>
                                            <br/>
                                            <br/>
                                            <li>
                                                <h5>Operating System Permissions</h5>
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="home1" title="MacOS">
                                                        <h6>MacOS</h6>
                                                        <p>For devices using MacOS Catalina or newer, Apple has
                                                            introduced
                                                            system-level
                                                            permissions for accessing screen sharing. If you've recently
                                                            updated
                                                            or
                                                            upgraded your device and can't screen share anymore, you'll
                                                            need to
                                                            update
                                                            the preferences by doing the following:</p>
                                                        <ol>
                                                            <li>Open up System Preferences on your Mac</li>
                                                            <li>Click on the Security and Privacy section</li>
                                                            <li>Scroll down to the Screen Recording section, and ensure
                                                                that
                                                                your
                                                                browser is check. You may need to unlock your settings
                                                                first in
                                                                the
                                                                bottom left.
                                                            </li>
                                                            <li>After doing this, fully Quit your browser and re-open it
                                                                so the
                                                                new
                                                                permissions are applied
                                                            </li>
                                                        </ol>
                                                    </Tab>
                                                    <Tab eventKey="profile1" title="Windows">
                                                        <h6>Windows</h6>
                                                        <p>At the moment Windows doesn't have any system-level
                                                            permissions for
                                                            screen
                                                            access, so if you're running into problems or getting an
                                                            error,
                                                            double-check
                                                            your browser settings.</p>
                                                    </Tab>
                                                </Tabs>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <br/>
                        <section id="GettingSupport" className="reachumuserguide-section-accorder">
                            <div className="reachumuserguide-card reachumuserguide-card-blue">
                                <h4>Support</h4>
                                <p>
                                    Need assistance with REACHUM? Our dedicated support team is here to help!
                                </p>
                                <p>
                                    Email us at <a
                                    href="mailto:support@reachum.com"> support@reachum.com </a> with any questions,
                                    feedback, or
                                    technical issues you encounter.
                                    We're committed to providing prompt assistance and ensuring your success with the
                                    platform.
                                </p>
                            </div>
                        </section>

                    </div>
                </div>
               <div>
                    <button
                        onClick={scrollToTop}
                        className="reachumuserguide-bottom-button"
                        aria-label="Scroll to top"
                    >
                       Go Top
                    </button>
               </div>
                <Foot/>
            </>
        )
}

export default Userguide;
