import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createPolllist, updatePlayslide} from '../../graphql/mutations';
import { useForm, useFieldArray } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../css/poll.css";
import DeleteIcon from "../../img/dash/Delete-Button-X.png";
import Add from "../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";

function Polls({id, handleClose66}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});
	const {fields, append, remove} = useFieldArray(
		{
			control,
			name: "ptest"
		}
	);
	const reorderurl = `https://edu.reachum.com/pollshowroom/${id}/${d}`;
	const erurl = `https://edu.reachum.com/pollsedit/${id}/${d}`;
	const onSubmit = async (data) => {
		try {
			const input = {
				id:d,
				pfname:data.pfname,
				ptimer:data.ptimer,
				pquestion:data.pquestion,
				ptest:data.ptest
			}
			await API.graphql({
				query: createPolllist,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.pfname,
				rurl: reorderurl,
				eurl: erurl
			};
			const allImages = [...images, urldata]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setOpensmessage(false);
			setSmessage("Submitted");
			setTimeout(() => {
				handleClose66()
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="orderlist">
				<div className="thiscenter">
				<h3>Poll</h3>
					</div>
		<form onSubmit={handleSubmit(onSubmit)} className="pollpanel">
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Poll Name : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="pfname"
					defaultValue=""
					ref={register({ required: true, maxLength: 60 })}
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Timer (Seconds) : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="ptimer"
					defaultValue=""
					ref={register({valueAsNumber: true,maxLength: 3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true,maxLength: 200 })}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Answers</Form.Label>
			<ol>
				{fields.map((item, index) => {
					return (
						<>
							<Table borderless>
								<tr>
						<li key={item.id}>
							<td>
								<input
									name={`ptest[${index}].id`}
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="hidden"
								/>
							<input
								name={`ptest[${index}].panswer`}
								defaultValue={`${item.panswer}`} // make sure to set up defaultValue
								ref={register({ maxLength: 150 })}
								className="orderlistinput"
							/>
							</td>
							<td className="polldelete">
								<img src={DeleteIcon} onClick={() => {remove(index)}} width="30" height="30" />
							</td>
						</li>
								</tr>
							</Table>
						</>
					);
				})}
			</ol>
				<div>
				<img src={Add} id="polladdsb" onClick={() => {
					append({ panswer: "" });
				}} />
				</div>
			</Form.Group>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{opensmessage ?
						<>
					<input type="submit" value="Submit" id="summitb"/>
					</>
						:
						<p className="redthis"> {smessage} </p>
					}
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>
			:""
	);
}
export default Polls;
