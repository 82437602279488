import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide, listLeaderboards} from "../../graphql/queries";
import {createLeaderboard, updateLeaderboard, updatePlayslide} from "../../graphql/mutations";
import {useParams,useNavigate} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Login from "../../pages/login";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import Full from "../../img/host/Layout.png";
import "../css/mroom.css";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import {newUpdatePlayslide} from "../../graphql/subscriptions";
import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";
import Modal from "react-bootstrap/Modal";
import Alldirections from "../../img/host/all-directions.png";
import moment from "moment";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-title"><ModalDialog {...this.props} /></Draggable>
    }
}

function Comroom() {
    const [urls, setUrls] = useState([]);
    const [lurl, setLurl] = useState("");
    const [user, setUser] = useState();
    const [lsize, setLsize] = useState(3);
    const [rsize, setRsize] = useState(9);
    const [isShow, setIsShow] = useState(true);
    const [isfull, setIsfull] = useState(false);
    const [isShows, setIsShows] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const [mylists, setMylists] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { id } = useParams();
    const [usertrackingid, setUsertackingid] =useState("");
    let history = useNavigate();
    sessionStorage.removeItem("reachumvideosilent");
    const rechumemail = localStorage.getItem("vreachumneweremail")
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(() => {
        (async () => {
            const input = {
                id,
                group: "noselfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        const fetchOrCreateLeaderboard = async () => {
            const starts = moment().format('MM/DD/YYYY hh:mm a');

            try {
                // Query existing leaderboard entries
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: "instructor@ins2.com" },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const plays = response.data.listLeaderboards.items;

                let trackingId;

                // If entry exists, use the existing ID
                if (plays.length > 0) {
                    trackingId = plays[0].id;
                } else {
                    // Otherwise create a new leaderboard entry
                    const input = {
                        name: "Instructor",
                        meetemail: "instructor@ins2.com",
                        answerid: id,
                        score: 0,
                        status: "Remote",
                        thisgamescore: 0,
                        timerin: starts
                    };

                    const createResponse = await API.graphql({
                        query: createLeaderboard,
                        variables: { input }
                    });

                    trackingId = createResponse.data.createLeaderboard.id;
                }

                // Update state and session storage
                setUsertackingid(trackingId);
                sessionStorage.setItem("edureachumthisid", trackingId);

            } catch (error) {
                console.error("Error managing leaderboard:", error);
            }
        };

        fetchOrCreateLeaderboard();
    }, [id]);
    useEffect(  () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user.attributes.email);
            })
            .catch((err) => console.log(err));
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        });
        async function fetchData() {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id}
                })
                const datas = response.data.getPlayslide;
                setUrls(datas.clink);
                const h = datas.playlistlinks;
                const lists = [];
                const ilists = h.length; // playlist length
                for (let j = 0; j < ilists; j++) {
                    const parray = h[j].rurl;
                    lists.push(parray)
                };
                setMylists(lists);
                const fuls = datas.fulls;
                if(fuls === true){
                    setLsize(12);
                    setRsize(12);
                    setIsOpen(false);
                    setIsShow(false);
                }else{
                    setLsize(3);
                    setRsize(9);
                    setIsOpen(true);
                    setIsShow(true);
                };
                const isplay = datas.vplaypause;
                if(isplay === true){
                    setIsShows(false)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
                }else{
                    setIsShows(true)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
                }
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    }, []);

    async function fetchvlick() {
        try {
            // Fetch playslide data
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });

            const data = response.data.getPlayslide;

            // Configure and set room link
            const roomLink = buildRoomLink(data.myrlink);
            setLurl(roomLink);

            // Set current URL
            setUrls(data.clink);

            // Process playlist links
            const playlistLinks = data.playlistlinks;
            const processedLists = playlistLinks.map(item => item.rurl);
            setMylists(processedLists);

            // Handle video selection based on count
            await handleVideoSelection(data.counts, playlistLinks);

            // Configure layout based on fullscreen setting
            configureLayout(data.fulls);

        } catch (error) {
            console.error("Error in fetchvlick:", error);
        }
    }

// Helper function to build room link with parameters
    function buildRoomLink(baseLink) {
        return `${baseLink}&embed&logo=on&screenshare=on&leaveButton=off&background=off&recording&breakout=on&roomIntegrations=on&autoSpotlight&pipButton=on`;
    }

// Helper function to handle video selection
    async function handleVideoSelection(countStr, playlistLinks) {
        const count = Number(countStr);

        if (count > 0) {
            setUrls(playlistLinks[count].rurl);
        } else {
            // Initialize with first video if count is 0
            const input = {
                id,
                aplaypause: false,
                clink: playlistLinks[0].rurl
            };

            await API.graphql({
                query: updatePlayslide,
                variables: { input }
            });
        }
    }

// Helper function to configure layout based on fullscreen setting
    function configureLayout(isFullscreen) {
        if (isFullscreen) {
            setLsize(12);
            setRsize(12);
            setIsOpen(false);
            setIsShow(false);
        } else {
            setLsize(3);
            setRsize(9);
            setIsOpen(true);
            setIsShow(true);
        }
    }
    const goups = async () =>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f+1;
        const h = datas.playlistlinks;
        const ilists = h.length; // playlist length
        let i = ilists - 1;
        if (t <= i) {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const godowns = async ()=>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f-1;
        const h = datas.playlistlinks;
        if (t <= 0) {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }

    const handlefullscreen = async () =>{
        const fulls = true;
        const ts = {id};
        const input = {
            id: ts.id,
            fulls:fulls
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setLsize(12);
        setRsize(12);
        setIsShow(false);
        setIsfull(true);
        hidebuttons()
    }
    const handlesmallscreen = async () =>{
        const fulls = false;
        const tds = {id};
        const input = {
            id: tds.id,
            fulls:fulls
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setLsize(3);
        setRsize(9);
        setIsShow(true);
        setIsfull(false);
        showbuttons()
    }
    const renderSlides = () => {
        return (
            <>
                    {mylists.map((slideContent, index) => (
                        <div className="slides" key={index}>
                                <img src={Noi} className="noi" width="125" height="72" onClick={ () => {
                                    const input = {
                                        id,
                                        aplaypause:false,
                                        clink: slideContent
                                    };
                                    API.graphql({
                                        query: updatePlayslide,
                                        variables: {input}
                                    });
                                    const thelist = document.getElementById('theFrame');
                                    thelist.src = slideContent;
                                    setShow(false)
                                }} />
                                <iframe src={slideContent} scrolling="no" className="editsframe" loading="lazy"></iframe>
                            </div>
                    ))}
            </>
        );
    }
    useHotkeys('n',()=>{
        goups()
    })
    useHotkeys('b',()=>{
       godowns()
    })
    useHotkeys('t',()=>{
        timeron()
    })


    useHotkeys('/', () => {
        if (isfull === false) {
            handlefullscreen()
        }else{
            handlesmallscreen()
        }
    },{}, [isfull])
    const timeron = async () =>{
        const input = {
            id,
            aplaypause:true
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    function hidebuttons() {
        setIsOpen(false)
    }
    function showbuttons() {
        setIsOpen(true)
    }
    const ends  = async() =>{
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Co-HOST</title>
            </Helmet>
            {!user ?
                <Login />
                :(
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={lsize}>
                                        {lurl !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="leftsid"
                                                src={lurl}
                                                loading="lazy"
                                                width="100%"
                                                scrolling="no"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="camera; microphone; fullscreen; speaker; display-capture"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={rsize}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                loading="lazy"
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    <Grid item xs={2}>
                                    </Grid>
                                    {isShow ?
                                        <Grid item xs={4} className="rightside">

                                                <img src={Full} onClick={handlefullscreen} className="mimg"/>

                                        </Grid>
                                        :
                                        <Grid item xs={4} className="rightside">

                                                <img src={Full} onClick={handlesmallscreen} className="mimg"/>

                                        </Grid>
                                    }

                                    <Grid item xs={4} className="leftside">
                                        {isOpen ?
                                            <>
                                                    <img src={Lists} onClick={()=>{setShow(true)}} className="mimg" />

                                                    <img src={Left} className="mimg" onClick={godowns}/>

                                                    <img src={Right} onClick={goups} className="mimg"/>

                                            </> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="endmeetingb">
                                            <img src={Ending} onClick={ends} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Modal
                                size="lg"
                                dialogAs={DraggableModalDialog}
                                show={show}
                                onHide={handleClose}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><img className="dragme" src={Alldirections} /></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {renderSlides()}
                                </Modal.Body>
                                <Modal.Footer >
                                </Modal.Footer>
                            </Modal>
                        </>
                )
            }
        </>
    );
}
export default Comroom;
