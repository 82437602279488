import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {createDesktopitems, createPlayslide, updateDesktopitems, updatePlayslide} from '../graphql/mutations';
import {getPlayslide, listDesktopitems} from "../graphql/queries";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";


function Shareroom(props) {
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [mename, setMename] = useState("");
    const [nname, setNname] = useState("");
    const [nownername, setNownername] = useState("");
    const [omid, setOmid] = useState("");
    const [playlistlinkse, setPlaylistlinkse] = useState([]);
    const [extime, setExtime] = useState();
    const [myData, setMyData] = useState([]);
    const [isopen, setIsopen] = useState(true);
    const id = props.data;

    useEffect(() => {
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const mname = datas.meetingname;
            setMename(mname);
            setOmid(datas.mid);
            setPlaylistlinkse(datas.playlistlinks)
            setNownername(datas.owner)
        })()
    }, [id]);

    async function senddata() {
        try {
            const input = {
                ctime: extime,
                mid: omid,
                myrlink: myData.myrlinks,
                clientrlink: myData.clientrlinks,
                meetingname: `${mename} from ${nownername}`,
                fulls: false,
                counts: 0,
                exiton: false,
                vplaypause: false,
                aplaypause: false,
                owner: nname
            };
            const res = await API.graphql({
                query: createPlayslide,
                variables: {input}
            });
            const datas = res.data.createPlayslide;
            let ids = datas.id;
            const replaced = playlistlinkse.map((element) => {
                const urlElements = element.rurl.split('/');
                const ee = element.eurl;
                let eurlElements;
                let neweurl;
                if(ee) {
                    eurlElements = ee.split('/');
                    eurlElements[4] = ids;
                    neweurl = eurlElements.join('/');
                }
                urlElements[4] = ids;
                const newUrl = urlElements.join('/');
                const newElements = {...element, rurl: newUrl, eurl: neweurl, adobeprojectid:"share" };
                return newElements;
            });
            try {
                const input = {
                    id: ids,
                    playlistlinks: replaced
                };
                API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
                const newitem = {
                    id: ids,
                    meetingname: `${mename} from ${nownername}`,
                    mid:omid
                }
                const response1 = await API.graphql({
                    query: listDesktopitems,
                    variables: {
                        filter: {
                            "owner": {
                                eq: nname
                            }
                        },
                        limit:10000
                    }
                })
                const datas1 = response1.data.listDesktopitems.items;
                if(datas1.length !== 0) {
                    const id = datas1[0].id;
                    const desktopitems = JSON.parse(datas1[0].deskitems);
                    const deskitemss = [...desktopitems, newitem]
                    const input ={
                        id,
                        deskitems: JSON.stringify(deskitemss)
                    }
                    await API.graphql({
                        query: updateDesktopitems,
                        variables: {input}
                    });
                }else{
                    const input1 = {
                        deskitems:JSON.stringify(newitem),
                        owner:nname
                    }
                    await API.graphql({
                        query: createDesktopitems,
                        variables: {input:input1}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            setIsopen(true)
            setNname("");
            document.getElementById("entername").innerHTML += "<li>" + nname + "</li>";

        } catch (err) {
            console.log(err)
        }finally {
            try {
                const input = {
                    id,
                    meetingname: `👁️‍🗨️${mename}`
                }
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        setUploadedmessage("Finished");
        setTimeout(() => {
            setUploadedmessage("")
        }, 1000)
    }
    async function startshare() {
        try {
            var thedate = moment();
            var newDate = thedate.add(1, "y");
            const utcendDate = newDate.utc().format();
            const data = {
                body: {
                    endDate: utcendDate,
                    roomMode: "group",
                    fields: ["hostRoomUrl"]
                }
            }
            const exptime = newDate.format('MM / DD / YYYY');
            return await API.post("eduwhereby","/eduwhereby",data)
                .then(datas => {
                    const myrlinks = datas.hostRoomUrl;
                    const clientrlinks = datas.roomUrl;
                    const daas = {
                        myrlinks,
                        clientrlinks
                    };
                    setMyData(daas)
                    setExtime(exptime);
                    setUploadedmessage("Sharing");
                    setIsopen(false)
                })
        } catch (err) {
            console.log(err)
        }
    }

    function changeItem(event) {
        setNname(event.target.value)

    }



    return (
            <>
                <div className="shareroompage">
                <p>Copy content and share it with other registered users, excluding the lesson log and leaderboard reports.</p>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                                <input
                                    value={nname}
                                    onChange={changeItem}
                                    className="sharemessage"
                                    type="email"
                                    placeholder="Email"
                                />
                        </Grid>
                        <Grid item xs={4} className="cccenter">
                                {isopen ?
                                    <button onClick={startshare} className="shareemailbutton">Share</button>
                                    :
                                    <button onClick={senddata} className="finishemailbutton">Finish</button>
                                }

                        </Grid>
                        <Grid item xs={2} className="cccenter">
                                {uploadedmessage}
                        </Grid>
                    </Grid>
                    <br/>
                    <ol id="entername">
                    </ol>
                </div>

            </>

    );
}
export default Shareroom;
