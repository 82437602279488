import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide, listLeaderboards} from "../../graphql/queries";
import {createLeaderboard, updateLeaderboard, updatePlayslide} from "../../graphql/mutations";
import {useParams,useNavigate} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Login from "../../pages/login";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import "../css/mroom.css";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Timeron from "../../img/host/Timer.png";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import Modal from 'react-modal';
import Fullscreen from "../../img/host/fullscreen.png";
import Expand from "../../img/host/expand.png";
import Close from "../../img/host/close.png";
import Reacltimewhiteboard from "../../img/host/whiteboard.png"
import Math from "../../img/host/mathematics.png"
import Qrcode from "../../img/host/qr-code.png"
import moment from "moment";

Modal.setAppElement('#root');
const Popup = ({ src }) => {
    return (
        <div className="popupqr">
            <div className="popupqr-inner">
                <iframe src={src} title="QR code" className="thisqrcode" loading="lazy"></iframe>
            </div>
        </div>
    );
};
function Imroom() {
    const [urls, setUrls] = useState([]);
    const [user, setUser] = useState();
    const [count, setCount] = useState(0);
    const [isShows, setIsShows] = useState(true);
    const [mylists, setMylists] = useState([]);
    const [isShowfulls, setIsShowfulls] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupqr, setShowPopupqr] = useState(false);
    const [showPopupwb, setShowPopupwb] = useState(false);
    const [showPopupmath, setShowPopupmath] = useState(false);
    const [showPopupto, setShowPopupto] = useState(false);
    const [usertrackingid, setUsertackingid] =useState("");
    const { id } = useParams();
    let history = useNavigate();
    sessionStorage.removeItem("reachumvideosilent")
    const rechumemail = localStorage.getItem("vreachumneweremail")
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(() => {
        (async () => {
            const input = {
                id,
                group: "noselfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        const fetchOrCreateLeaderboard = async () => {
            const starts = moment().format('MM/DD/YYYY hh:mm a');

            try {
                // Query for existing leaderboard entries
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: "instructor@ins.com" },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const plays = response.data.listLeaderboards.items;
                let trackingId;

                if (plays.length > 0) {
                    // Use existing leaderboard entry
                    trackingId = plays[0].id;
                } else {
                    // Create new leaderboard entry
                    const input = {
                        name: "Instructor",
                        meetemail: "instructor@ins.com",
                        answerid: id,
                        score: 0,
                        status: "In-Person",
                        thisgamescore: 0,
                        timerin: starts
                    };

                    const createResponse = await API.graphql({
                        query: createLeaderboard,
                        variables: { input }
                    });

                    trackingId = createResponse.data.createLeaderboard.id;
                }

                // Update state and session storage
                setUsertackingid(trackingId);
                sessionStorage.setItem("edureachumthisid", trackingId);

            } catch (error) {
                console.error("Error fetching or creating leaderboard:", error);
            }
        };

        fetchOrCreateLeaderboard();
    }, [id]);
    useEffect(() => {
        // Get current authenticated user
        const getCurrentUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user.attributes.email);
            } catch (err) {
                console.error("Authentication error:", err);
            }
        };

        // Fetch playslide data and update state
        const fetchPlayslideData = async () => {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables: { id }
                });

                const data = response.data.getPlayslide;

                // Set play/pause state
                setIsShows(!data.vplaypause);

                // Process playlist links
                const playlistLinks = data.playlistlinks;
                const processedLists = playlistLinks.map(item => item.rurl);
                setMylists(processedLists);

                // Handle count-based playlist selection
                await handlePlaylistSelection(data.id, playlistLinks, count);
            } catch (error) {
                console.error("Error fetching playslide data:", error);
            }
        };

        // Helper function to handle playlist selection based on count
        const handlePlaylistSelection = async (contentId, playlistLinks, currentCount) => {
            const maxIndex = playlistLinks.length - 1;

            if (currentCount <= maxIndex) {
                // Update current URL
                setUrls(playlistLinks[currentCount].rurl);

                // Update backend with current selection
                const input = {
                    id: contentId,
                    aplaypause: false,
                    clink: playlistLinks[currentCount].rurl
                };

                await API.graphql({
                    query: updatePlayslide,
                    variables: { input }
                });
            } else {
                // Reset count if it exceeds playlist length
                setCount(0);
            }
        };

        // Execute auth and data fetching
        getCurrentUser();
        fetchPlayslideData();

    }, [count, id]);

    const renderSlides = () => {
        return (
            <>
                    {mylists.map((slideContent, index) => (
                        <div className="slides" key={index}>
                                <img src={Noi} className="noi" width="125" height="72" onClick={ () => {
                                    const input = {
                                        id,
                                        aplaypause:false,
                                        clink: slideContent
                                    };
                                    API.graphql({
                                        query: updatePlayslide,
                                        variables: {input}
                                    });
                                    const thelist = document.getElementById('theFrame');
                                    thelist.src = slideContent;
                                    setShowPopup(false);
                                }} />
                                <iframe src={slideContent} scrolling="no" className="editsframe"></iframe>
                            </div>
                    ))}
            </>
        );
    }

    useHotkeys('n',()=>{
        setCount(counts => counts + 1);
    })
    useHotkeys('b',()=>{
        setCount(counts => counts - 1);
    })
    useHotkeys('t',()=>{
        timeron()
    })
    const ends  = async() =>{
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    function handlePopupButtonClick() {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
        setShowPopup(true);
        setIsShowfulls(false)
    }
    function handlefullscreen(){
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
    }
    function handleminiscreen(){
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
    function handleFullscreenChange() {
        if (!document.fullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
        if (!document.webkitFullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
    }
    function openwhiteboard(){
        setShowPopupwb(!showPopupwb);
    }
    function handlePopupqrButtonClick(){
        setShowPopupqr(!showPopupqr);
    }
    const closePopups = () => {
        setShowPopupwb(false);
    };

    function openmath(){
        setShowPopupmath(!showPopupmath);
    }
    const closePopupmath = () => {
        setShowPopupmath(false);
    };

    function timeron(){
        setShowPopupto(!showPopupto);
    }
    const closePopupto = () => {
        setShowPopupto(false);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>In-Person HOST</title>
            </Helmet>
            {!user ?
                <Login />
                :(
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                loading="lazy"
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={9} className="centerside">
                                        <>
                                            {isShowfulls ?
                                                <img src={Expand} onClick={()=>{handlefullscreen();setIsShowfulls(false)}} className="mimg" alt="Full Screen"/>
                                                :
                                                <img src={Fullscreen} onClick={() => {handleminiscreen();setIsShowfulls(true)}} className="mimg" alt="Mini Screen"/>
                                            }
                                                <img src={Lists} onClick={handlePopupButtonClick} className="mimg" alt="Content List" />
                                            <img src={Qrcode} onClick={handlePopupqrButtonClick} className="mimg" alt="QR code" />
                                            {showPopupqr && <Popup src={`/qrcodepage/${id}`} />}
                                                <img src={Timeron} onClick={timeron} className="mimg" />
                                            {showPopupto && (
                                                <div className="popupwb" onClick={closePopupto}>
                                                    <iframe src="/timer" title="Time On" className="thiswb"></iframe>
                                                </div>)}
                                            <img src={Reacltimewhiteboard} onClick={openwhiteboard} className="mimg" alt="Whiteboard"/>
                                            {showPopupwb && (
                                                <div className="popupwb" onClick={closePopups}>
                                                    <iframe src="/realtimewhiteboard" title="White Board" className="thiswb"></iframe>
                                                </div>)}
                                            <img src={Math} onClick={openmath} className="mimg" />
                                            {showPopupmath && (
                                                <div className="popupwb" onClick={closePopupmath}>
                                                    <iframe src="/desmos" title="Desmo" className="thiswb"></iframe>
                                                </div>)}
                                                <img src={Left} className="mimg" onClick={() => {
                                                    setCount(count - 1);
                                                    if (count === 0) {
                                                        setCount(0)
                                                    }
                                                }} alt="Left"/>

                                                <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="Right"/>

                                        </>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="ileftside">
                                            <img src={Ending} onClick={ends} alt="Exit"/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Modal isOpen={showPopup} ariaHideApp={false}>
                                <div className="rightside">
                                <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
                                </div>
                                <br/>
                                {renderSlides()}
                            </Modal>
                    </>
                )
            }
        </>
    );
}
export default Imroom;
