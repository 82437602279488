import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {listLeaderboards, getPlayslide, listAnswerslists} from '../../../graphql/queries';
import {Table, Button} from 'react-bootstrap';
import moment from "moment";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import People from "../../../img/overview/people.png"
import {deleteAnswerslist, deleteLeaderboard} from "../../../graphql/mutations";
import Info from "../../../img/dash/pop-up-icon.svg"


function Overview(props) {
    const  id  = props.data;
    const [thislength, setThislength] = useState(0);
    const [corrca, setCorrca] = useState(0);
    const [hiDu, setHiDu] = useState(0);
    const [loDu, setLoDu] = useState(0);
    const [hiscor, setHiscor] = useState(0);
    const [loscor, setLoscor] = useState(0);
    const [avera, setAvera] = useState(0);
    const [earliestt, setEarliestt] = useState("");
    const [latestt, setLatestt] = useState("");
    const [filteredRids, setFilteredRids] = useState([]);
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        fetchDas();
    }, [id]);

    const fetchDas = async () => {
        let dcounts = [];
        let scounts = [];
        let allItems = [];
        let nextToken = null;

        try {
            // Fetch all data using pagination
            do {
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 1000, // More reasonable limit per request
                        nextToken: nextToken
                    }
                });

                const items = response.data.listLeaderboards.items;
                nextToken = response.data.listLeaderboards.nextToken;
                allItems = [...allItems, ...items];

            } while (nextToken);

            // Process the data after fetching all items
            let filteredData = allItems;

            if (filteredData.length !== 0) {
                setThislength(filteredData.length);

                filteredData.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata);
                });

                // Sort by score in descending order
                scounts.sort((a, b) => {
                    return b.score - a.score;
                });

                scounts.forEach((e) => {
                    dcounts.push(e);
                });

                // Process time durations
                const result = dcounts.map(item => {
                    const timein = moment(item.timerin, [
                        'dddd MMMM Do YYYY h:mm:ss a',
                        'MMMM Do YYYY h:mm:ss a',
                        'MM/DD/YYYY h:mm a',
                    ]);
                    const timeout = moment(item.timerout, [
                        'dddd MMMM Do YYYY h:mm:ss a',
                        'MMMM Do YYYY h:mm:ss a',
                        'MM/DD/YYYY h:mm a',
                    ]);

                    const ttsout = timeout.format('MM/DD/YYYY hh:mm a');
                    const ttsin = timein.format('MM/DD/YYYY hh:mm a');

                    const startTime = new Date(ttsin);
                    const endTime = new Date(ttsout);
                    const totalDuration = endTime - startTime;

                    // Calculate hours and minutes
                    const hours = Math.floor(totalDuration / (1000 * 60 * 60));
                    const minutes = Math.floor((totalDuration % (1000 * 60 * 60)) / (1000 * 60));

                    return {
                        ...item,
                        dura: `${hours}h ${minutes}m`,
                    };
                });

                // Parse durations to find min and max
                function parseDuration(duration) {
                    const hoursMatch = duration.match(/(\d+)h/);
                    const minutesMatch = duration.match(/(\d+)m/);

                    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
                    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

                    return hours * 60 + minutes;
                }

                const durationsInMinutes = result.map(task => parseDuration(task.dura));

                if (durationsInMinutes.length > 0) {
                    const maxDuration = Math.max(...durationsInMinutes);
                    const minDuration = Math.min(...durationsInMinutes);
                    const maxTask = result[durationsInMinutes.indexOf(maxDuration)];
                    const minTask = result[durationsInMinutes.indexOf(minDuration)];


                    if(maxTask.dura === "NaNh NaNm"){
                        maxTask.dura=""
                    }
                    if(minTask.dura === "NaNh NaNm"){
                        minTask.dura=""
                    }
                    setHiDu(maxTask.dura);
                    setLoDu(minTask.dura);
                }

                // Calculate score stats
                const scores = result.map(item => item.score).filter(score => score !== undefined);

                if (scores.length > 0) {
                    const hscore = Math.max(...scores);
                    setHiscor(hscore);
                    const lscore = Math.min(...scores);
                    setLoscor(lscore);
                }

                // Calculate average score
                const calculateAverageScore = (data) => {
                    const validScores = data.filter(item => item.score !== undefined).map(item => item.score);
                    const totalScore = validScores.reduce((acc, score) => acc + score, 0);
                    const averageScore = validScores.length ? totalScore / validScores.length : 0;
                    const tt = Number.parseFloat(averageScore).toFixed(1);
                    return tt;
                };

                const averageScore = calculateAverageScore(filteredData);
                setAvera(averageScore);

                // Find earliest and latest timestamps
                function findEarliestAndLatest(dates) {
                    let earliestTimein = null;
                    let latestTimeout = null;

                    dates.forEach((item) => {
                        const timein = moment(item.timerin, [
                            'dddd MMMM Do YYYY h:mm:ss a',
                            'MMMM Do YYYY h:mm:ss a',
                            'MM/DD/YYYY h:mm a',
                        ]);

                        const timeout = moment(item.timerout, [
                            'dddd MMMM Do YYYY h:mm:ss a',
                            'MMMM Do YYYY h:mm:ss a',
                            'MM/DD/YYYY h:mm a',
                        ]);

                        if (timein.isValid() && (earliestTimein === null || timein.isBefore(earliestTimein))) {
                            earliestTimein = timein;
                        }
                        if (timeout.isValid() && (latestTimeout === null || timeout.isAfter(latestTimeout))) {
                            latestTimeout = timeout;
                        }
                    });
                    return { earliestTimein, latestTimeout };
                }

                const { earliestTimein, latestTimeout } = findEarliestAndLatest(filteredData);
                setEarliestt(earliestTimein ? earliestTimein.format('MM/DD/YYYY hh:mm a') : '');
                setLatestt(latestTimeout ? latestTimeout.format('MM/DD/YYYY hh:mm a') : '');

                // Calculate correct answer rate
                let correctCount = 0;
                let totalCount = 0;
                filteredData.forEach(item => {
                    if (item.questions) {
                        item.questions.forEach(question => {
                            if (question.correctanswer !== null) {
                                totalCount++;
                                if (question.correctanswer === "correct") {
                                    correctCount++;
                                }
                            }
                        });
                    }
                });

                const correctRate = totalCount > 0 ? (correctCount / totalCount) * 100 : 0;
                setCorrca(correctRate);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
        }
    };
    async function fetchData() {
        const response = await API.graphql({
            query: getPlayslide,
            variables: {id}
        });
        const datas = response.data.getPlayslide;
        const edatas = datas.playlistlinks;
        if (edatas === null) {
            console.log("hello");
        } else {
            const keywords = ["drawoptionshowroom", "mathfasteranswerroom", "fasteranswerimgroom", "multioptionshowroom", "flipshowroom",
                "multidesmosshowroom", "showsequencing", "showdragdrop", "optionshowroom"];

            const modifiedRids = edatas
                .filter(item => keywords.some(keyword => item.rurl.includes(keyword)))
                .map(item => `2${item.rid}`);
            setFilteredRids(modifiedRids)
        }
    }
    useEffect(() => {
        fetchDasl();
    }, [id]);

    async function fetchDasl() {
        let allItems = [];
        let nextToken = null;
        try {
            do {
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 1000, // More reasonable limit per request
                        nextToken: nextToken
                    }
                });

                const items = response.data.listLeaderboards.items;
                nextToken = response.data.listLeaderboards.nextToken;

                allItems = [...allItems, ...items];

            } while (nextToken);
            setThisdatas(allItems);
        } catch (err) {
            console.error("Error fetching data:", err);
        } finally {

        }
    }
    async function clearalldata(){
        let text = "Are you sure you want to delete all participants lesson log, leaderboard data, and each game records? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                try {
                    let cl = thisdatas.length;
                    for (let i = 0; i < cl; i++) {
                        const input = {
                            id: thisdatas[i].id
                        };
                        await API.graphql({
                            query: deleteLeaderboard,
                            variables: {input}
                        });
                    }
                } catch (err) {
                    console.log(err)
                }
         try{
                const response = await API.graphql({
                    query: listAnswerslists,
                    variables: {
                        filter: {
                            or: filteredRids.map(rid => ({answerid: {eq: rid}})) // ✅ Creates `or` condition
                        }
                    }
                });
                const itemsToDelete = response.data.listAnswerslists.items;
                if (itemsToDelete.length === 0) {

                }
                for (const item of itemsToDelete) {
                    API.graphql({
                        query: deleteAnswerslist,
                        variables: {input: {id: item.id}}
                    });
                }
                  window.location.reload();
              } catch (err) {
                  console.log(err)
                  }

            } catch (error) {
                console.error("❌ Error deleting items:", error);
            }
        }
    }

    return (
        <>
            <div>
                       <div className="peopleline">
                           &nbsp;&nbsp; &nbsp; <img src={People} alt="People"/> <span className="biggertext"> : {thislength} </span>
                           <br/><br/>
                       </div>
                &nbsp; <Button onClick={clearalldata} variant="danger" size="sm"> Delete Results </Button>&nbsp;
                       <div className="upgradenew2">
                           <img src={Info} />
                           <span className="tooltiptext2">Delete all participants lesson log, leaderboard data, and each game records. </span>
                       </div>
               <Table striped bordered className="thistable">
                    <thead>
                    <tr>
                        <th>Lowest Score</th>
                        <td>{loscor}</td>
                    </tr>
                    <tr>
                        <th>Highest Score</th>
                        <td>{hiscor}</td>
                    </tr>
                    <tr>
                        <th>Average Score</th>
                        <td>{avera}</td>
                    </tr>
                    <tr>
                        <th>Correct Answer Rate</th>
                        <td>{`${corrca.toFixed(1)}%`}</td>
                    </tr>
                    <tr>
                        <th>Shortest Duration</th>
                        <td>{loDu}</td>
                    </tr>
                    <tr>
                        <th>Longest Duration</th>
                        <td>{hiDu}</td>
                    </tr>
                    <tr>
                        <th>Earliest Time In</th>
                        <td>{earliestt}</td>
                    </tr>
                    <tr>
                        <th>Latest Time Out</th>
                        <td>{latestt}</td>
                    </tr>
                    </thead>
                </Table>
            </div>
        </>
    )
}

export default Overview;
