import {API} from "aws-amplify";
import React, { useEffect, useState, useRef } from "react";
import {createLeaderboard, updateLeaderboard, updatePlayslide,} from "../../graphql/mutations";
import {getPlayslide, listLeaderboards} from "../../graphql/queries";
import {useNavigate,useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import "../css/preview.css";
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import {Helmet} from "react-helmet";
import Ending from "../../img/host/Exit-Button.png";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import Replaybutton from "./replay.png";
import moment from "moment";

function SelfStudy() {
    const history = useNavigate();
    const [urls, setUrls] = useState([]);
    const [count, setCount] = useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const [totalslide, setTotalslide] = useState(1);
    const [currentslide, setCurrentslide] = useState(1);
    const [openthisbutton, setOpenthisbutton] = useState(true)
    const [showthis, setShowthis] = useState(true);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [usertrackingid, setUsertackingid] =useState("");
    const [isValid, setIsValid] = useState(true);
    const iframeRef = useRef(null);
    const { id } = useParams();
     useEffect(() => {
        (async () => {
            const input = {
                id,
                group: "selfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, [id]);
    useEffect(() => {
        // Get stored user data
        const username = localStorage.getItem("vreachumnewername");
        const userEmail = localStorage.getItem("vreachumneweremail");
        const hasUpdatedUsername = sessionStorage.getItem("updatedusername");
        const currentTime = moment().format('MM/DD/YYYY hh:mm a');

        // Update state based on stored values
        setYourusername(username);

        if (userEmail) {
            setIsOpen(false);
        }

        if (hasUpdatedUsername) {
            setShowthis(false);
        }

        // Only proceed if we have a user email
        const fetchOrCreateUserRecord = async () => {
            if (!userEmail) return;

            try {
                // Check if user already has a record for this answer
                const { data } = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            meetemail: { eq: userEmail },
                            answerid: { eq: id }
                        },
                        limit: 10000
                    }
                });

                const userRecords = data.listLeaderboards.items;

                if (userRecords.length > 0) {
                    // User record exists, store the ID
                    const recordId = userRecords[0].id;
                    setUsertackingid(recordId);
                    sessionStorage.setItem("edureachumthisid", recordId);
                } else {
                    // Create new user record
                    const newRecord = {
                        name: username,
                        meetemail: userEmail,
                        answerid: id,
                        score: 0,
                        status: "Self-Study",
                        thisgamescore: 0,
                        timerin: currentTime
                    };

                    const response = await API.graphql({
                        query: createLeaderboard,
                        variables: { input: newRecord }
                    });

                    const newRecordId = response.data.createLeaderboard.id;
                    setUsertackingid(newRecordId);
                    sessionStorage.setItem("edureachumthisid", newRecordId);
                }
            } catch (error) {
                console.error("Error fetching or creating user record:", error);
            }
        };

        fetchOrCreateUserRecord();
    }, [id]);
    useEffect(() => {
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });
            const datas = response.data.getPlayslide;
            const h = datas.playlistlinks;
            let i = h.length;
            setTotalslide(i);
            if (count < i) {
                setUrls(h[count].rurl);
                setCurrentslide(count + 1)
            }else if(count >= i){
                setOpenthisbutton(false)
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);

    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
            try {
                const ssname = stateData.meetname;
                const ssemail = stateData.meetemail;
                localStorage.setItem("vreachumnewername", ssname);
                localStorage.setItem("vreachumneweremail", ssemail);
                setShowthis(false);
                sessionStorage.setItem("updatedusername", "1")
                if (iframeRef.current && iframeRef.current.contentWindow) {
                    iframeRef.current.contentWindow.postMessage('dataUpdated', '*');
                }
            } catch (err) {
                console.log(err)
            }
        }
    }
    async function ends(){
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
       let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };
    async function handlereplay() {
        window.location.reload()
    }
    useEffect(() => {
        let isUpdating = false;
        const onClose = async () => {
            if (isUpdating) return;
            isUpdating = true;
            const thisid = sessionStorage.getItem("edureachumthisid");
            if (!thisid) {
                isUpdating = false;
                return;
            }
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                };

                await API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.error("Error updating leaderboard:", err);
            } finally {
                setTimeout(() => {
                    isUpdating = false;
                }, 1000);
            }
        };
        window.addEventListener('beforeunload', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            onClose();
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('pagehide', onClose);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Self-Study</title>
            </Helmet>
        <div>
                    {urls !== "" && (
                        <iframe
                            ref={iframeRef}
                            className="preiframe"
                            id="theFrame"
                            src={urls}
                            loading="lazy"
                            width="100%"
                            height="100vh"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                <Grid container spacing={0} className="bottoms">
                    <Grid item xs={2} className="rightside">
                        {openthisbutton ?
                            <p className="greenselfthis"> {currentslide} / {totalslide} </p>
                            :
                            <div className="upgradenew5" id="3">
                                <img src={Replaybutton} onClick={handlereplay} className="mimg" alt="Replay"/>
                                <span className="tooltiptext5">Click the 'Restart' button followed by the 'Leave Page' button in the panel.</span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={8} className="centerside">
                                    <img src={Left} className="mimg" onClick={() => {
                                        setCount(count - 1);
                                        if (count === 0) {
                                            setCount(0)
                                        }
                                    }} alt="left"/>
                                   &nbsp;
                                    <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="right" />
                    </Grid>
                    <Grid item xs={2}>
                        <div className="endmeetingb">
                            <img src={Ending} onClick={ends} alt="end"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Modal
                show={showthis}
                centered
                size="sm"
            >
                <Modal.Body>
                    <div className="cccenter">
                        <h4 className="greenselfthis">Welcome!</h4>
                        {isOpen ?
                            <>
                            <br/>
                            <p className="pxsize">Enter your email and a display name.<br/>
                                All games are anonymous.<br/>
                                Emails are used solely for reporting.</p>
                            <Form className="market-header">
                                <Form.Item>
                                    <Input
                                        type="email"
                                        value={stateData.meetemail}
                                        onChange={meetemail => {
                                            setStateData({
                                                ...stateData,
                                                meetemail: meetemail
                                            });
                                            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                            const isValidemail = emailRegex.test(meetemail);
                                            setIsValid(isValidemail);
                                        }}
                                        maxLength={100}
                                        placeholder="Email"
                                        className="craeteroomgame"
                                    />
                                    {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                </Form.Item>
                                <Form.Item>
                                    <Input
                                        type="text"
                                        value={stateData.meetname}
                                        onChange={meetname => setStateData({
                                            ...stateData,
                                            meetname: meetname
                                        })}
                                        maxLength={50}
                                        placeholder="Display Name"
                                        className="craeteroomgame"
                                    />
                                </Form.Item>
                                <Form.Item>
                                            <Button
                                                disabled={!stateData.meetemail || !stateData.meetname}
                                                onClick={handleSubmit2}
                                                variant="success"
                                            >
                                                &nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;
                                            </Button>
                                </Form.Item>
                            </Form>
                            </>
                            :
                            <div className="cccenter">
                                <br/>
                                <p className="pxsize">Display Name:</p>
                                <input type="text" className="centerthisinput" value={yourusername} name="message"
                                       onChange={handleChange222}/>
                                <br/>
                                <br/>
                                <Button variant="success"  onClick={() => {
                                    localStorage.setItem("vreachumnewername", yourusername);
                                    setShowthis(false);
                                    sessionStorage.setItem("updatedusername", "1")
                                }}>&nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;</Button>
                                <br/>
                                <br/>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            </>
    );
}
export default SelfStudy;
