import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listAnswerslists, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Table,Button} from 'react-bootstrap';
import "./style.css";
import Correctimg from "../../img/view/check-mark.png";
import Incorrectimh from "../../img/view/close.png";
import Noanswer from "../../img/view/question-mark.png";
import Contents from "../../img/view/content-management.png"
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Downloadlessonlog from "./leaderboard/downloadlessonlog";
var XLSX = require("xlsx");

function LessonLog() {
    const [myData, setMyData] = useState([]);
    const [mysafeData, setMysafeData] = useState([]);
    const {id} = useParams();
    const [thislength, setThislength] = useState(0);
    const [roomname, setRoomname] = useState("");
    const [isSortedAlpha, setIsSortedAlpha] = useState(false);
    const [isSortedlth, setIsSortedlth] = useState(false);
    const [isSortedti, setIsSortedti] = useState(false);
    const [isSortedto, setIsSortedto] = useState(false);
    const [isSorteddura, setIsSorteddura] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [openrearchform, setOpenrearchform] = useState(false);
    const [opensafebutton, setOpensafebutton] = useState(false);
    useEffect(() => {
        fetchsafeask();
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname);
        })()
    }, []);
    useEffect(() => {
        fetchDas();
    }, [id]);
    const fetchDas = async () => {
        let allItems = [];
        let nextToken = null;

        try {
            // Paginated fetching
            do {
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 1000, // More reasonable per-request limit
                        nextToken: nextToken
                    }
                });

                const items = response.data.listLeaderboards.items;
                nextToken = response.data.listLeaderboards.nextToken;
                allItems = [...allItems, ...items];

            } while (nextToken);

            // Process data only after all items are fetched
            if (allItems.length !== 0) {
                setThislength(allItems.length);

                // Sort by score in descending order
                const sortedData = [...allItems].sort((a, b) => b.score - a.score);

                // Process each item with time calculations
                const processedData = sortedData.map(item => {
                    const timein = moment(item.timerin, [
                        'dddd MMMM Do YYYY h:mm:ss a',
                        'MMMM Do YYYY h:mm:ss a',
                        'MM/DD/YYYY h:mm a',
                    ]);

                    const timeout = moment(item.timerout, [
                        'dddd MMMM Do YYYY h:mm:ss a',
                        'MMMM Do YYYY h:mm:ss a',
                        'MM/DD/YYYY h:mm a',
                    ]);

                    const ttsout = timeout.format('MM/DD/YYYY hh:mm a');
                    const ttsin = timein.format('MM/DD/YYYY hh:mm a');

                    const startTime = new Date(ttsin);
                    const endTime = new Date(ttsout);
                    const totalDuration = endTime - startTime;

                    // Calculate hours and minutes (fix: division for hours calculation)
                    const hours = Math.floor(totalDuration / (1000 * 60 * 60));
                    const minutes = Math.floor((totalDuration % (1000 * 60 * 60)) / (1000 * 60));

                    return {
                        ...item,
                        dura: `${hours}h ${minutes}m`,
                    };
                });

                setMyData(processedData);
            } else {
                setMyData([]);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {

        }
    };


    const fetchsafeask = async () => {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const mysafeaskroom = datas.playlistlinks;
        const rurls = mysafeaskroom.filter(item => item.rurl && item.rurl.includes('/answerroom/'));
            if(rurls.length > 0) {
                const rurlLinks = rurls.map(item => ({ rurl: item.rurl, title: item.title }));
                const extractedStrings = rurlLinks.map(item => ({
                    id: item.rurl.substring(item.rurl.lastIndexOf('/') + 1),
                    title: item.title
                }))
                async function fetchData() {
                    const scounts = [];
                    const dcounts = [];
                    try {
                        for (const { id, title } of extractedStrings) {
                            const variables = {
                                filter: {
                                    answerid: {
                                        eq: id
                                    }
                                }
                            };
                            const response = await API.graphql({
                                query: listAnswerslists,
                                variables
                            })
                            const datas = response.data.listAnswerslists;
                            const yy = datas.items;
                            scounts.push(yy);
                            dcounts.push(title);
                            const mergedArray = scounts.map((array, index) => {
                                if (array.length > 0) {
                                    setOpensafebutton(true);
                                    return [{
                                        title: dcounts[index],
                                        data: array
                                    }];
                                }
                                return array;
                            });
                            if(mergedArray){
                                setMysafeData(mergedArray);
                            }
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                fetchData();
            }
    };
    const renderreports = () => {
        return myData.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.meetemail}</td>
                        <td>
                            <Accordion>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>Activities</Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered>
                                            <thead>
                                            <tr>
                                                <th>Activity</th>
                                                <th> Correct/ Incorrect Answer</th>
                                                <th> Score</th>
                                                <th>Duration(Sec)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {item.questions && item.questions.map((item, index) => {
                                                const thisas = item.correctanswer;
                                                let thisanswer;
                                                if (thisas === "correct") {
                                                    thisanswer = `${Correctimg}`
                                                } else if (thisas === "wrong") {
                                                    thisanswer = `${Incorrectimh}`
                                                } else if (thisas === "No answer") {
                                                    thisanswer = `${Noanswer}`
                                                } else {
                                                    thisanswer = `${Contents}`
                                                }
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td><p className="shortertext">{item.question}</p></td>
                                                            <td><img src={thisanswer} width="24px"/></td>
                                                            <td>{item.thisscore}</td>
                                                            <td>{item.duration}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </td>
                        <td>{item.score}</td>
                        <td>{item.timerin}</td>
                        <td>{item.timerout}</td>
                        <td>{item.dura === "NaNh NaNm" ? "" : item.dura}</td>
                        <td> {item.status || ""}</td>
                        <td> Reachum</td>
                    </tr>
                )
        })
    }
    const toggleEmail = () => {
        if (isSortedAlpha) {
            const tsorted = [...myData].sort((a, b) => b.meetemail.localeCompare(a.meetemail));
            setMyData(tsorted);
        }else {
            const sorted = [...myData].sort((a, b) => a.meetemail.localeCompare(b.meetemail));
            setMyData(sorted);
        }
        setIsSortedAlpha(prevState => !prevState);
    }
    const toggleScore = () => {
        if (isSortedlth) {
            const ssorted = [...myData].sort((a, b) => b.score - a.score);
            setMyData(ssorted);
        }else {
            const soorted = [...myData].sort((a, b) => a.score - b.score);
            setMyData(soorted);
        }
        setIsSortedlth(prevState => !prevState);
    }
    const toggleDura = () => {
        if (isSorteddura) {
            const sdorted = [...myData].sort((a, b) => parseDuration(b.dura) - parseDuration(a.dura));
            setMyData(sdorted);
        }else {
            const sodorted = [...myData].sort((a, b) => parseDuration(a.dura) - parseDuration(b.dura));
            setMyData(sodorted);
        }
        setIsSorteddura(prevState => !prevState);
    }
    function parseDuration(duration) {
        const hoursMatch = duration.match(/(\d+)h/); // Match hours
        const minutesMatch = duration.match(/(\d+)m/); // Match minutes

        const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
        const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

        return hours * 60 + minutes; // Convert to total minutes
    }
    const toggleTimein = () => {
        if (isSortedti) {
            const stdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdorted);
        }else {
            const sotdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdorted);
        }
        setIsSortedti(prevState => !prevState);
    }
    const toggleTimeout = () => {
        if (isSortedto) {
            const stdoorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdoorted);
        }else {
            const sotdoroted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdoroted);
        }
        setIsSortedto(prevState => !prevState);
    }
    const handleSearch = () => {
        const results = myData.filter(item =>
            item.meetemail.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(results);
        setOpenrearchform(true)
    };
    const rendersearchreports = ()=> {
        return searchResults.map((item, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        <Accordion>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>Activities</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th> Correct/ Incorrect Answer</th>
                                            <th> Score</th>
                                            <th>Duration(Sec)</th>
                                        </tr></thead>
                                        <tbody>
                                        {item.questions && item.questions.map((item, index) => {
                                            const thisas = item.correctanswer;
                                            let thisanswer;
                                            if(thisas === "correct"){
                                                thisanswer = `${Correctimg}`
                                            }else if(thisas === "wrong"){
                                                thisanswer = `${Incorrectimh}`
                                            }else if(thisas === "No answer"){
                                                thisanswer = `${Noanswer}`
                                            }else{
                                                thisanswer = `${Contents}`
                                            }
                                            return(
                                                <>
                                                    <tr key={index}>
                                                        <td><p className="shortertext">{item.question}</p></td>
                                                        <td><img src={thisanswer} width="24px"/></td>
                                                        <td>{item.thisscore}</td>
                                                        <td>{item.duration}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                    <td>{item.score}</td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td>
                        {item.dura === "NaNh NaNm" ? "" : item.dura}
                    </td>
                </tr>
        ))
    }
    const resetdata = () => {
        setOpenrearchform(false);
        setSearchQuery("")
    }
   const downloadFile=()=>{
       const rows = mysafeData.flatMap((array, index) => {
           const title = array[0]?.title || 'Untitled';
           return array[0]?.data.map(item => ({
               title,  // Add title to each item
               name: item.name,
               email: item.email || 'N/A',
               answer: item.answers
           }));
       });
       const worksheet = XLSX.utils.json_to_sheet(rows);
       const workbook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");
       XLSX.utils.sheet_add_aoa(worksheet, [["Title", "Name", "Email", "Answer"]], { origin: "A1" });

       XLSX.writeFile(workbook, `${roomname} - SafeAsk.xlsx`, { compression: true });
    }
    return (
        <>
            <div className="cccenter">
                <br/>
                <p>Lesson Log</p>
                <h4> {roomname} </h4>
                <Downloadlessonlog data={id} />
                <div className="leftlessonlogform">
                    &nbsp; &nbsp;
                    <input
                        type="email"
                        placeholder="Search by email"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className="searchlessonlogform"
                    />
                    {!openrearchform ?
                        <button onClick={handleSearch} className="searchbutton">Search</button>
                        :
                        <button onClick={resetdata} className="searchbutton">Clear</button>
                    }
                </div>
                <div className="rightsidethis">
                    <p>
                        {opensafebutton ?
                            <Button onClick={downloadFile} variant="outline-danger" size="sm"> Download SafeAsk file </Button>
                          :
                            <span className="redthissafe">If a SafeAsk file exists, a button will appear here.</span>
                        }
                            &nbsp; &nbsp;&nbsp; &nbsp; Participants: {thislength} &nbsp; &nbsp;</p>
                </div>
                <Table bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th> Name</th>
                        <th><span onClick={toggleEmail}
                                  className="togglemenu">{isSortedAlpha ? "Email" : "Email"}</span></th>
                        <th> Activity Log</th>
                        <th><span onClick={toggleScore} className="togglemenu">{isSortedlth ? "Score" : "Score"}</span>
                        </th>
                        <th><span onClick={toggleTimein}
                                  className="togglemenu">{isSortedti ? "Time In" : "Time In"}</span></th>
                        <th><span onClick={toggleTimeout}
                                  className="togglemenu">{isSortedto ? "Time Out" : "Time Out"}</span></th>
                        <th><span onClick={toggleDura}
                                  className="togglemenu"> {isSorteddura ? "Duration" : "Duration"}</span>
                        </th>
                        <th> Learning Mode </th>
                        <th> Source </th>
                    </tr>
                    </thead>
                    <tbody>
                    {openrearchform ?
                        <>
                            {rendersearchreports()}
                        </> :
                        <>
                            {renderreports()}
                        </>
                    }
                    </tbody>
                </Table>
            </div>

        </>

    )
}

export default LessonLog;
