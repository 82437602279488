import React from "react";
import { Routes , Route} from "react-router-dom";
import Room from "./components/view/room";
import Iroom from "./components/view/iroom";
import Mcroom from "./components/view/mcroom";
import Rform from "./components/view/rform";
import Userguide from "./pages/userguide";
import Dashboard from "./pages/dashboard/dashboard";
import Rehearse from "./pages/rehearse";
import Editroom from "./pages/editroom";
import Profile from "./pages/profile";
import Imroom from "./components/host/imroom";
import Mroom from "./components/host/mroom";
import Comroom from "./components/cohost/mroom";
import Vroom from "./components/groupleader/vroom";
import Vform from "./components/groupleader/vform";
import Timer from "./components/timer";
import Vshowroom from "./components/uploadfiles/vid/vshowroom";
import Pdfshowroom from "./components/uploadfiles/pdf/pdfshowroom";
import Imgshowroom from "./components/uploadfiles/img/imgshowroom";
import Gifshowroom from "./components/gif/gifshowroom";
import Leaderboard from "./components/reports/leaderboard";
import Hostleaderboard from "./components/reports/leaderboard/hostleaderboard";
import Pollshowroom from "./components/poll/pollshowroom";
import QRcodepage from "./pages/qrcodepage";
import SSQRcodepage from "./pages/ssqrcodepage";
import Ipqrcodepage from "./pages/ipqrcodepage";
import Inviteform1 from "./pages/invitepeople/inviteform1";
import Gifroom from "./components/uploadfiles/uploadgif/gifroom";
import Iurlshowroom from "./components/uploadurl/iurlshowroom";
import Urlshowroom from "./components/uploadurl/urlshowroom";
import Vimeoshowroom from "./components/vimeo/vimeoshowroom";
import Fasteranswerroom from "./components/leaderboardgames/fastanswer/fasteranswerroom";
import Optionshowroom from "./components/leaderboardgames/options/optionshowroom";
import Flipshowroom from "./components/leaderboardgames/flipcard/flipshowroom";
import Multioptionshowroom from "./components/leaderboardgames/multipleoptions/multioptionshowroom";
import Pptxshowroom from "./components/uploadfiles/pptx/pptxshowroom";
import Newtextshowroom from "./components/slides/newtextshowroom";
import Textshowroom from "./components/slides/textshowroom";
import Newtextslideedit from "./components/slides/newtextslideedit";
import Textslideedit from "./components/slides/textslideedit";
import Ideorecordr from "./components/recordvideo/ideorecordr";
import Ideopreviewpager from "./components/recordvideo/ideopreviewpager";
import Fastansweredit from "./components/leaderboardgames/fastanswer/fastansweredit";
import Multioptionsedit from "./components/leaderboardgames/multipleoptions/multioptionsedit";
import Optionsedit from "./components/leaderboardgames/options/optionsedit";
import Ideoedit from "./components/recordvideo/ideoedit";
import Pollsedit from "./components/poll/pollsedit";
import Imgedit from "./components/uploadfiles/img/imgedit";
import Giphyedit from "./components/gif/giphyedit";
import Flipcardsedit from "./components/leaderboardgames/flipcard/flipcardsedit";
import Urledit from "./components/uploadurl/urledit";
import Vimeoedit from "./components/vimeo/vimeoedit";
import Videoedit from "./components/uploadfiles/vid/videoedit";
import Gifsedit from "./components/uploadfiles/uploadgif/gifsedit";
import Blackboardedit from "./components/blackboard/blackboardedit";
import Blackboardshowroom from "./components/blackboard/blackboardshowroom";
import Drawoptionsedit from "./components/leaderboardgames/drawoptions/drawoptionsedit";
import Drawoptionshowroom from "./components/leaderboardgames/drawoptions/drawoptionshowroom";
import Realtimewhiteboard from "./components/realtimeblackboard/realtimewhiteboard";
import Answerroom from "./components/answers/answerroom";
import Answeredit from "./components/answers/answeredit";
import Fastercorrctanswer from "./components/leaderboardgames/fastanswer/fastercorrctanswer";
import Drawcorrctanswer from "./components/leaderboardgames/drawoptions/drawcorrctanswer";
import Flipcardcorrctanswer from "./components/leaderboardgames/flipcard/flipcardcorrctanswer";
import Multicorrctanswer from "./components/leaderboardgames/multipleoptions/multicorrctanswer";
import Onecorrctanswer from "./components/leaderboardgames/options/onecorrctanswer";
import Tutorials from "./pages/tutorials";
import Showdragdrop from "./components/leaderboardgames/taptap/showdragdrop";
import Categorytaptapedit from "./components/leaderboardgames/taptap/categorytaptapedit";
import Correcttaptap from "./components/leaderboardgames/taptap/correcttaptap";
import Morereports from "./components/reports/leaderboard/morereports";
import Showsequencing from "./components/leaderboardgames/sequencing/showsequencing";
import Sequencingedit from "./components/leaderboardgames/sequencing/sequencingedit";
import Correctsequencing from "./components/leaderboardgames/sequencing/correctsequencing";
import Rankingedit from "./components/ranking/rankingedit";
import Showranking from "./components/ranking/showranking";
import Adobe from "./components/adobeexpress/adobe";
import Adobeshow from "./components/adobeexpress/adobeshow";
import Adobeedit from "./components/adobeexpress/adobeedit";
import Fastanswerimgedit from "./components/leaderboardgames/fastanswer/fastanswerimgedit";
import Fasteranswerimgroom from "./components/leaderboardgames/fastanswer/fasteranswerimgroom";
import Fastercorrctimganswer from "./components/leaderboardgames/fastanswer/fastercorrctimganswer";
import SelfStudy from "./pages/ondemand/selfStudy";
import Inviteondemandform from "./pages/invitepeople/inviteondemandform";
import Desmos from "./components/desmos/desmos";
import Multidesmosedit from "./components/leaderboardgames/mathgames/multipleoptions/multidesmosedit";
import Multidesmosshowroom from "./components/leaderboardgames/mathgames/multipleoptions/multidesmosshowroom";
import Multidesmoscorrctanswer from "./components/leaderboardgames/mathgames/multipleoptions/multidesmoscorrctanswer";
import Mathfastanswer from "./components/leaderboardgames/mathgames/fastanswer/mathfastanswer";
import Mathfasteranswerroom from "./components/leaderboardgames/mathgames/fastanswer/mathfasteranswerroom";
import Mathfastansweredit from "./components/leaderboardgames/mathgames/fastanswer/mathfastansweredit";
import Mathfastercorrctanswer from "./components/leaderboardgames/mathgames/fastanswer/mathfastercorrctanswer";
import Newteslideedit from "./components/slides/newteslideedit";
import Newteshowroom from "./components/slides/newteshowroom";
import Error from "./pages/error";
import Editmathslide from "./components/mathslide/editmathslide";
import Mathslideshow from "./components/mathslide/mathslideshow";
import Mediaflipcardshow from "./components/flashcards/mediaflipcardshow";
import Mediaflipcardedit from "./components/flashcards/mediaflipcardedit";
import Newerslideedit from "./components/slides/newerslideedit";
import Newershowroom from "./components/slides/newershowroom"
import Reports from "./components/reports/reports";
import ShareLessonPlan from "./pages/lessonPlan/shareLessonPlan";
import PublicLessonPlan from "./pages/lessonPlan/publicLessonPlan";
import ShareReports from "./components/reports/shareReports";
import Wordcloudroom from "./components/wordcloud/wordcloudroom";
import Wordcloudedit from "./components/wordcloud/wordcloudedit";
import LessonLog from "./components/reports/lessonLog";
import Uploadmp3 from "./components/uploadfiles/uploadmp3/uploadmp3";
import Mp3edit from "./components/uploadfiles/uploadmp3/mp3edit";
import Mp3room from "./components/uploadfiles/uploadmp3/mp3room";
import Sedit from "./components/uploadfiles/recorderscreen/sedit";
import Sshowroom from "./components/uploadfiles/recorderscreen/sshowroom";

export const UserContext = React.createContext();

const App = () => {
    return (
        <div>
        <Routes>
            <Route path={"/host/:id/:mid"} element={<Mroom />} />
            <Route path="/cohost/:id/:mid" element={<Comroom />} />
            <Route path={"/ihost/:id/:mid"} element={<Imroom />} />
            <Route path={"/groupleader/:id/:mid"} element={<Vroom />} />
            <Route path={"/view/:id"} element={<Room />} />
            <Route path={"/rform/:id"} element={<Rform />} />
            <Route path={"/vform/:id"} element={<Vform />} />
            <Route path={"/iview/:id"} element={<Iroom />} />
            <Route path={"/view/mc/:id"} element={<Mcroom />} />
            <Route path="/ideorecordr/:id" element={<Ideorecordr />} />
            <Route path="/ideopreviewpager/:id" element={<Ideopreviewpager />} />
            <Route path={"/vshowroom/:id/:rid"} element={<Vshowroom />} />
            <Route path="/fastansweredit/:id/:rid" element={<Fastansweredit />} />
            <Route path="/fastercorrctanswer/:id/:rid" element={<Fastercorrctanswer />} />
            <Route path="/fastanswerimgedit/:id/:rid" element={<Fastanswerimgedit />} />
            <Route path="/fastercorrctimganswer/:id/:rid" element={<Fastercorrctimganswer />} />
            <Route path="/drawcorrctanswer/:id/:rid" element={<Drawcorrctanswer />} />
            <Route path="/flipcardcorrctanswer/:id/:rid" element={<Flipcardcorrctanswer />} />
            <Route path="/multicorrctanswer/:id/:rid" element={<Multicorrctanswer />} />
            <Route path="/onecorrctanswer/:id/:rid" element={<Onecorrctanswer />} />
            <Route path={"/mp3room/:id/:rid"} element={<Mp3room />} />
            <Route path={"/uploadmp3/:id"} element={<Uploadmp3 />} />
            <Route path={"/Mp3edit/:id/:rid"} element={<Mp3edit />} />
            <Route path="/imgedit/:id/:rid" element={<Imgedit />} />
            <Route path="/vimeoedit/:id/:rid" element={<Vimeoedit />} />
            <Route path="/videoedit/:id/:rid" element={<Videoedit />} />
            <Route path="/sedit/:id/:rid" element={<Sedit />} />
            <Route path="/sshowroom/:id/:rid" element={<Sshowroom />} />
            <Route path="/urledit/:id/:rid" element={<Urledit />} />
            <Route path="/answeredit/:id/:rid" element={<Answeredit />} />
            <Route path="/answerroom/:id/:rid" element={<Answerroom />} />
            <Route path="/flipcardsedit/:id/:rid" element={<Flipcardsedit />} />
            <Route path="/multioptionsedit/:id/:rid" element={<Multioptionsedit />} />
            <Route path="/optionsedit/:id/:rid" element={<Optionsedit />} />
            <Route path="/pollsedit/:id/:rid" element={<Pollsedit />} />
            <Route path="/ideoedit/:id/:rid" element={<Ideoedit />} />
            <Route path="/newtextshowroom/:id/:rid" element={<Newtextshowroom />} />
            <Route path="/textshowroom/:id/:rid" element={<Textshowroom />} />
            <Route path="/newtextslideedit/:id/:rid" element={<Newtextslideedit />} />
            <Route path="/textslideedit/:id/:rid" element={<Textslideedit />} />
            <Route path="/giphyedit/:id/:rid" element={<Giphyedit />} />
            <Route path="/gifsedit/:id/:rid" element={<Gifsedit />} />
            <Route path="/flipshowroom/:id/:rid" element={<Flipshowroom />} />
            <Route path={"/pdfshowroom/:id/:rid"} element={<Pdfshowroom />} />
            <Route path={"/imgshowroom/:id/:rid"} element={<Imgshowroom />} />
            <Route path={"/gifshowroom/:id/:rid"} element={<Gifshowroom />} />
            <Route path="/morereports/:id" element={<Morereports />} />
            <Route path="/showdragdrop/:id/:rid" element={<Showdragdrop />} />
            <Route path="/correcttaptap/:id/:rid" element={<Correcttaptap />} />
            <Route path="/categorytaptapedit/:id/:rid" element={<Categorytaptapedit />} />
            <Route path={"/gifroom/:id/:rid"} element={<Gifroom />} />
            <Route path={"/hostleaderboard/:id"} element={<Hostleaderboard />} />
            <Route path={"/leaderboard/:id"} element={<Leaderboard />} />
            <Route path={"/pptxshowroom/:id/:rid"} element={<Pptxshowroom />} />
            <Route path={"/urlshowroom/:id/:rid"} element={<Urlshowroom />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path={"/iurlshowroom/:id/:rid"} element={<Iurlshowroom />} />
            <Route path={"/Multioptionshowroom/:id/:rid"} element={<Multioptionshowroom />} />
            <Route path={"/pollshowroom/:id/:rid"} element={<Pollshowroom />} />
            <Route path={"/vimeoshowroom/:id/:rid"} element={<Vimeoshowroom />} />
            <Route path={"/blackboardshowroom/:id/:rid"} element={<Blackboardshowroom />} />
            <Route path={"/blackboardedit/:id/:rid"} element={<Blackboardedit />} />
            <Route path={"/drawoptionshowroom/:id/:rid"} element={<Drawoptionshowroom />} />
            <Route path={"/drawoptionsedit/:id/:rid"} element={<Drawoptionsedit />} />
            <Route path={"/fasteranswerroom/:id/:rid"} element={<Fasteranswerroom />} />
            <Route path={"/fasteranswerimgroom/:id/:rid"} element={<Fasteranswerimgroom />} />
            <Route path="/optionshowroom/:id/:rid" element={<Optionshowroom />} />
            <Route path="/userguide" element={<Userguide />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/timer" element={<Timer />} />
            <Route path="/showsequencing/:id/:rid" element={<Showsequencing />} />
            <Route path="/correctsequencing/:id/:rid" element={<Correctsequencing />} />
            <Route path="/sequencingedit/:id/:rid" element={<Sequencingedit />} />
            <Route path={"/showranking/:id/:rid"} element={<Showranking />} />
            <Route path={"/rankingedit/:id/:rid"} element={<Rankingedit />} />
            <Route path="/adobe/:id" element={<Adobe />} />
            <Route path="/adobeshow/:id/:rid" element={<Adobeshow />} />
            <Route path="/adobeedit/:id/:rid" element={<Adobeedit />} />
            <Route path="/multidesmosedit/:id/:rid" element={<Multidesmosedit />} />
            <Route path="/multidesmosshowroom/:id/:rid" element={<Multidesmosshowroom />} />
            <Route path="/multidesmoscorrctanswer/:id/:rid" element={<Multidesmoscorrctanswer />} />
            <Route path="/mathfasteranswerroom/:id/:rid" element={<Mathfasteranswerroom />} />
            <Route path="/mathfastansweredit/:id/:rid" element={<Mathfastansweredit />} />
            <Route path="/mathfastercorrctanswer/:id/:rid" element={<Mathfastercorrctanswer />} />
            <Route path="/mathfastanswer/:id" element={<Mathfastanswer />} />
            <Route path="/newteslideedit/:id/:rid" element={<Newteslideedit />} />
            <Route path="/newteshowroom/:id/:rid" element={<Newteshowroom />} />
            <Route path={"/editmathslide/:id/:rid"} element={<Editmathslide />} />
            <Route path={"/mathslideshow/:id/:rid"} element={<Mathslideshow />} />
            <Route path="/shareReports" element={<ShareReports />} />
            <Route path={"/mediaflipcardedit/:id/:rid"} element={<Mediaflipcardedit />} />
            <Route path={"/mediaflipcardshow/:id/:rid"} element={<Mediaflipcardshow />} />
            <Route path={"/newershowroom/:id/:rid"} element={<Newershowroom />} />
            <Route path={"/newerslideedit/:id/:rid"} element={<Newerslideedit />} />
            <Route path={"/shareLessonPlan/:id"} element={<ShareLessonPlan />} />
            <Route path={"/publicLessonPlan/:id"} element={<PublicLessonPlan />} />
            <Route path={"/wordcloudedit/:id/:rid"} element={<Wordcloudedit />} />
            <Route path={"/wordcloudroom/:id/:rid"} element={<Wordcloudroom />} />
            <Route path="/rehearse/:id" element={<Rehearse />} />
            <Route path="/lessonLog/:id" element={<LessonLog />} />
            <Route path="/qrcodepage/:id" element={<QRcodepage />} />
            <Route path="/ssqrcodepage/:id" element={<SSQRcodepage />} />
            <Route path="/Ipqrcodepage/:id" element={<Ipqrcodepage />} />
            <Route path={"/desmos"} element={<Desmos />} />
            <Route path={"/reports/:id"} element={<Reports />} />
            <Route path={"/editroom/:id"} element={<Editroom />} />
            <Route path={"/selfStudy/:id"} element={<SelfStudy />} />
            <Route path={"/inviteondemandform/:id"} element={<Inviteondemandform />} />
            <Route path="/inviteform1/:id" element={<Inviteform1 />} />
            <Route path="/realtimewhiteboard" element={<Realtimewhiteboard />} />
            <Route path="/error" element={<Error />} />
            <Route path="/" element={<Dashboard />} />
        </Routes>
        </div>
    )
}

export default App
