import React, {useState, useEffect} from 'react'
import Showitemlist from './showitemlist'
import {API} from "aws-amplify";
import {getSequencinggame, listAnswerslists, listLeaderboards} from "../../../graphql/queries";
import {Button, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Playsequcing from "../../../img/menu/dis/sequencing.gif"
import moment from "moment";
import {v4 as uuid} from 'uuid';
import {createAnswerslist, createLeaderboard} from "../../../graphql/mutations";
import Submitteds from "../../../img/overview/submit.png";
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import People from "../../../img/overview/people.png";
import Grid from "@material-ui/core/Grid";
import Close from "../../../img/host/close.png";
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";
import Modal from "react-modal";

export default function Showsequencing() {
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState();
  const [isShowpeoples, setIsShowpeoples] = useState(false);
  const [peoplenumbers, setPeoplenumbers] = useState(0);
  const [peoples, setPeoples] = useState([]);
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const starts = moment().format('MM/DD/YYYY hh:mm a');
  const [answerednumber, setAnswerednumbers] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [thisiscorrect, setThisiscorrect] = useState(0);
  const [thisiswrong, setThisiswrong] = useState(0);
  sessionStorage.setItem("edureachumthisstartstimer", starts)
  const start = new Date();
  const d = uuid();
  sessionStorage.setItem("edureachumthisstarttimer", start.toString());
  const [thisname, setThisname] = useState(localStorage.getItem('vreachumnewername') || '');
  const [thisemail, setThisemail] = useState(localStorage.getItem('vreachumneweremail')||'');
  const loadUserData = () => {
    try {
      const storedName = localStorage.getItem('vreachumnewername');
      const storedEmail = localStorage.getItem('vreachumneweremail');

      if (storedName) setThisname(storedName);
      if (storedEmail) setThisemail(storedEmail);

      // Set dataLoaded to true if either name or email exists
      if (storedName || storedEmail) {
        setIsShowpeoples(false)
      }

    } catch (error) {
      console.error('Error loading data from localStorage:', error);
    }
  };
  useEffect(() => {
    loadUserData();

    const handleMessage = (event) => {
      if (event.data === 'dataUpdated') {
        loadUserData();
      }
    };

    window.addEventListener('message', handleMessage);

    const timeoutId = setTimeout(() => {
      loadUserData();
    }, 500);

    // Clean up listener and timeout when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    fetchDas();
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getSequencinggame,
        variables: {id:rid}
      })
      const datas = response.data.getSequencinggame;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      sessionStorage.setItem("edureachumthisquestion", ques)
      const caes = datas.orders;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  useEffect(() => {
    const starts = moment().format('MM/DD/YYYY hh:mm a');

    const createAnswersEntry = async () => {
      if (!thisemail) {
        setIsShowpeoples(true);
        return;
      }

      try {
        const input = {
          id: d,
          answerid: `2${rid}`,
          name: thisname,
          email: thisemail
        };

        await API.graphql({
          query: createAnswerslist,
          variables: { input }
        });
        sessionStorage.setItem("thisflipshowroomreachumid", d)
      } catch (err) {
        console.log("Error creating answers entry:", err);
      }
    };

    const checkAndCreateLeaderboard = async () => {
      if (!thisemail) return;

      try {
        // First check if a leaderboard entry exists
        const response = await API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              meetemail: { eq: thisemail },
              answerid: { eq: id }
            },
            limit: 10000
          }
        });

        const plays = response.data.listLeaderboards.items;

        if (plays.length > 0) {
          // Existing leaderboard found
          const existingEntry = plays[0];
          sessionStorage.setItem("edureachumthisid", existingEntry.id);

          if (existingEntry.questions) {
            const serializedArray = JSON.stringify(existingEntry.questions);
            sessionStorage.setItem("edureachumthisques", serializedArray)

          }
          if(existingEntry.score !== undefined) {
            sessionStorage.setItem("edureachumthistotalscore", existingEntry.score)
          }else{
            sessionStorage.setItem("edureachumthistotalscore", 0)
          }
        } else {
          // Create new leaderboard entry
          const input = {
            name: thisname,
            meetemail: thisemail,
            answerid: id,
            score: 0,
            thisgamescore: 0,
            timerin: starts
          };

          try {
            const responsee = await API.graphql({
              query: createLeaderboard,
              variables: { input }
            });

            // Fix accessing the id from the response
            const thistimerid = responsee.data.createLeaderboard.id;
            sessionStorage.setItem("edureachumthisid", thistimerid);
            sessionStorage.setItem("edureachumthistotalscore", 0)
          } catch (err) {
            console.error("Error creating leaderboard:", err);
          }
        }
      } catch (err) {
        console.error("Error querying leaderboards:", err);
      }
    };

    // Execute both operations
    const initializeData = async () => {
      await createAnswersEntry();
      await checkAndCreateLeaderboard();
    };

    initializeData();
  }, []);
  function savethisdata(){
    sessionStorage.setItem("sequencinggames",JSON.stringify(thiscate));
    sessionStorage.setItem("sequencinggamescounter", counter);
    sessionStorage.setItem("sequencinggamesques", quest);
    setIsgameopen(true);
  }
  useEffect(() => {
    fetchData();
    const createanswerslist = API.graphql(
        {
          query: newCreateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const updateanswerslist = API.graphql(
        {
          query: newUpdateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const deleteanswerslist = API.graphql(
        {
          query: newDeleteAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    return () => {
      createanswerslist.unsubscribe();
      updateanswerslist.unsubscribe();
      deleteanswerslist.unsubscribe()
    }
  }, []);
  const fetchData = async () => {
    const response = await API.graphql({
      query: listAnswerslists,
      variables: {
        filter: {
          "answerid": {
            eq: `2${rid}`
          }
        },
        limit: 10000
      }
    })

    let filteredData = response.data.listAnswerslists.items;
    const merged = mergeRecordsByEmail(filteredData);
    setPeoples(merged);
    setPeoplenumbers(merged.length);
    const countAnswered = merged.filter(record => record.answers === "submited").length;
    setAnswerednumbers(countAnswered);
    const countcorrctAnswered = merged.filter(record => record.answers === "submited");
    const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
    const iscorrectnumber = Number(countAnswered - iswrongnumber);
    setThisiscorrect(iscorrectnumber);
    setThisiswrong(iswrongnumber);
  }
  const mergeRecordsByEmail = (records) => {
    const mergedRecords = {};
    records.forEach(record => {
      const {email, answerid, answers, aimgurl} = record;
      if (!mergedRecords[email]) {
        mergedRecords[email] = {
          email,
          name: record.name,
          answerid: null,
          answers: null,
          aimgurl:null
        };
      }
      if (answers === "submited") {
        mergedRecords[email].answerid = answerid;
        mergedRecords[email].answers = answers;
        mergedRecords[email].aimgurl = aimgurl;
      } else if (mergedRecords[email].answerid === null) {
        mergedRecords[email].answerid = answerid;
      }
    });

    // Return merged records as an array
    return Object.values(mergedRecords);
  };

  return (
      <>
      <div>
              {isgameopen ?
                  <>
                 <Showitemlist />
                  </>
                  :
                  <div className="cccenter">
                    <br/>
                    <h2> Sequencing </h2>
                    <img src={Playsequcing} className="playsquencingborder"/>
                    <br/>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                    <div>
                      {isShowpeoples ?
                          <div>
                            <br/>
                            <img src={People} onClick={() => setShowPopup(true)}/>
                          </div>
                          : ""
                      }
                    </div>
                    <br/>
                    <p>Tap to select an item,
                      <br/>then tap again to place the item
                      in the proper slot in the sequence.<br/>
                      Tapping an occupied slot replaces the
                      earlier selection.<br/>
                      To clear all slots, click the “Reset” button.
                    </p>
                  </div>
              }
      </div>
        <Modal isOpen={showPopup} ariaHideApp={false}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="leftside">
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="rightside">
                <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
              </div>
            </Grid>
          </Grid>
          <div className="cccenter">
            <h3><img src={People} /> <span className="redthis">{peoplenumbers}</span> &nbsp; <img src={Submitteds} /> <span className="redthis">{answerednumber}</span> &nbsp; <img
                src={Correctimg} width="25px" /> <span className="redthis">{thisiscorrect}</span> &nbsp; <img src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
            </h3>
            <br/>
          </div>
          <div>
            <Table hover size="sm" className="sizeforbtable">
              <thead>
              <tr>
                <th> Name</th>
                <th className="cccenter"> Correct / Incorrect</th>
                <th className="cccenter"> % Correct </th>
              </tr>
              </thead>
              <tbody>
              {peoples.map((record, index) => (
                  <tr
                      key={index}
                      style={{
                        color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                        marginBottom: '10px',
                      }} className="peopleviewsconitem">
                    <td className="leftsitethis">{record.name}</td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <img src={Correctimg} width="20px"/>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <img src={Wrongimg} width="16px"/>
                      ) : (
                          <span></span>
                      )}
                    </td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <span>{record.aimgurl}</span>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <span>0%</span>
                      ) : (
                          <span></span>
                      )}
                    </td>
                  </tr>
              ))}
              </tbody>
            </Table>
          </div>
        </Modal>
</>
  )
}
